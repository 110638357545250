import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-us-buttons',
  templateUrl: './contact-us-buttons.component.html',
  styleUrls: ['./contact-us-buttons.component.scss'],
})
export class ContactUsButtonsComponent implements OnInit {
  @Input() titleOne: string | undefined;
  @Input() titleTwo: string | undefined;
  @Input() titleThree: string | undefined;
  @Input() buttonType: string | undefined;
  @Input() buttonColorOne: string | undefined;
  @Input() buttonColorTwo: string | undefined;
  @Input() buttonColorThree: string | undefined;
  @Input() buttonHeight: string | undefined;
  @Input() buttonWidth: string | undefined;
  @Input() buttonTextColorOne: string | undefined;
  @Input() buttonTextColorTwo: string | undefined;
  @Input() buttonTextColorThree: string | undefined;
  @Input() buttonWidthSM: string | undefined;
  @Input() buttonFill: string | undefined;
  @Input() buttonClass: string | undefined;
  @Input() buttonExpand: string | undefined;
  @Input() btnOneDisable: boolean = false;
  @Input() btnTwoDisable: boolean = false;
  @Input() btnThreeDisable: boolean = false;
  @Input() btnOneDisableColor: boolean = false;
  @Input() btnTwoDisableColor: boolean = false;
  @Input() btnThreeDisableColor: boolean = false;
  @Input() borderRadius: string | undefined;
  @Input() buttonId: string | undefined;
  @Input() link = '';
  @Output() btnOneClick = new EventEmitter<any>();
  @Output() btnTwoClick = new EventEmitter<any>();
  @Output() btnThreeClick = new EventEmitter<any>();

  value: any;
  appControl: FormControl<any> | any;
  @Input() appformGroup: FormGroup | any;
  @Input() appControlName: any;

  ngOnInit(): void {}

  change(e: any) {
    this.onTouched();

    this.value = e.target.value;

    this.appControl = this.appformGroup.controls[this.appControlName] as FormControl;

    this.onChanged(e.target.value);
  }
  onTouched() {
    throw new Error('Method not implemented.');
  }
  onChanged(value: any) {
    throw new Error('Method not implemented.');
  }

  buttonOneClicked() {
    this.btnOneClick.emit();
  }
  buttonTwoClicked() {
    this.btnTwoClick.emit();
  }
  buttonThreeClicked() {
    this.btnThreeClick.emit();
  }
}
