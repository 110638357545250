import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-get-approved',
  templateUrl: './get-approved.component.html',
})
export class GetApprovedComponent implements OnInit {
  isSmallScreen = false;

  language: string | any = 'en-US';

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.isSmallScreen = result.matches;
    });
    this.language = localStorage.getItem('language');
  }

  showTitle1: boolean = false;
  showTitle1Detail: boolean = false;
  showTitle2: boolean = true;
  showTitle2Detail: boolean = true;
  showTitle3: boolean = false;
  showTitle3Detail: boolean = false;
  showTitle4: boolean = false;
  showTitle4Detail: boolean = false;

  showT1() {
    this.showTitle1 = true;
    this.showTitle1Detail = true;

    this.showTitle2 = false;
    this.showTitle2Detail = false;

    this.showTitle3 = false;
    this.showTitle3Detail = false;

    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT2() {
    this.showTitle2 = true;
    this.showTitle2Detail = true;

    this.showTitle1 = false;
    this.showTitle1Detail = false;

    this.showTitle3 = false;
    this.showTitle3Detail = false;

    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT3() {
    this.showTitle3 = true;
    this.showTitle3Detail = true;

    this.showTitle2 = false;
    this.showTitle2Detail = false;

    this.showTitle1 = false;
    this.showTitle1Detail = false;

    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT4() {
    this.showTitle4 = true;
    this.showTitle4Detail = true;

    this.showTitle3 = false;
    this.showTitle3Detail = false;

    this.showTitle2 = false;
    this.showTitle2Detail = false;

    this.showTitle1 = false;
    this.showTitle1Detail = false;
  }
}
