import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-for-merchants',
  templateUrl: './for-merchants.component.html',
  styleUrls: ['./for-merchants.component.scss'],
})
export class ForMerchantsComponent implements OnInit, AfterContentInit, AfterContentChecked {
  isExpanded = false;
  language: string | any = 'en-US';
  isPopupVisible = false;
  formData: { name: string } = { name: '' }; // Define the formData object

  getImageClasses(): string {
    const isArabic = document.body.getAttribute('lang') === 'ar';
    return isArabic ? 'flippable-image' : '';
  }

  steps = [
    {
      number: 1,
      title: 'Step 1',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
      active: false,
    },
    {
      number: 2,
      title: 'Step 2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
      active: false,
    },
    {
      number: 3,
      title: 'Step 3',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
      active: false,
    },
    {
      number: 4,
      title: 'Step 4',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.',
      active: false,
    },
  ];

  showStep(index: number) {
    this.steps.forEach((step, i) => {
      step.active = i === index;
    });
  }

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.language = localStorage.getItem('language');
  }

  ngAfterContentInit(): void {
    this.language = localStorage.getItem('language');
  }

  ngAfterContentChecked(): void {
    this.language = localStorage.getItem('language');
  }

  // togglePopup() {
  //   this.isPopupVisible = !this.isPopupVisible;
  // }

  closePopup() {
    this.isPopupVisible = false;
  }
}
