<section class="Policy">
  <div class="doc">
    <div class="contents">
      <h2>Terms & Conditions - Merchants</h2>
      <div class="sub-contents">
        <h4>1. Overview</h4>
        <p>
          Our customers privacy matters to us. This Privacy Policy describes the policies and
          procedures of Halalah Trading Company (“Hala”, “we”, “our”, “us”) pertaining to the
          collection, use and disclosure of personal data received from Hala customers, by using our
          products and services offered. Also, upon your agreement to Hala’s terms and conditions,
          your consent is provided to Hala to use your information for the purposes set out in this
          privacy policy, provided that Hala is committed to protect data or information used,
          managed, transmitted and/or stored with a high degree of confidentiality, integrity, and
          availability.
        </p>

        <h4>2. Hala Information Collection & Applicability</h4>
        <p>
          This policy applies to all collected data of Hala, by any means of collection included but
          not limited to the following: Personal identification data provided by you, as our
          customer to Hala directly, as well as data that is collected by learning about you from
          your usage of our application and services. De-personalized data that allows us to
          understand and enhance the operation of our services. Hala collects personal identifiable
          information that you provide voluntarily, while using Hala’s services and as agreed in
          Hala’sterms & conditions. Also, Hala collects information about customers’ transactions
          behavior, such as details of any used bank accounts.
        </p>

        <h4>3. Hala Data Protection</h4>
        <p>
          Hala will use its best efforts to ensure that the information submitted by the customer
          remains private, and is used only for the purpose for which it is submitted. Hala follows
          the Kingdom’s along with SAMA cybersecurity framework requirements and NCA standards to
          provide the maximum protection and privacy to your data, including using of HTTPS and
          regularly monitoring our systems for possible vulnerabilities and attacks. Any payment
          transactions will be protected and safeguarded by encryption. However, we cannot warrant
          the security of any data that you send us. There is no guarantee that data may not be
          accessed, disclosed, altered, or destroyed by breach since internet is not completely
          secure.
        </p>

        <h4>4. Hala Customer Data Disclosure</h4>
        <p>
          By acknowledgment this policy, we may disclose your personal data to our other services
          inside the Kingdom; and/or third parties in the following circumstances:
        </p>
        <ul>
          <li>
            Sharing data in the case of a liquidation, merge, sale, receivership, or transfer of
            Hala assets, to the prospective buyer of the business and their advisers.
          </li>
          <li>Sharing data to ensure services availability and optimization.</li>
          <li>
            Sharing data to third parties that support us in providing our services to you or
            perform certain functions on our behalf, including IT support services or professional
            services.
          </li>
          <li>
            Sharing data if it’s required by local regulatory authority of competent jurisdiction,
            or by law, or if we believe that such action is necessary to ensure, defend or enforce
            our rights, including exchange information with other companies and organizations for
            the purposes of cyber security protection, credit risk reduction and fraud fighting.
          </li>
        </ul>
        <p>
          In order to coordinate with governmental requests, summons or court orders, or to protect
          other users, or our assets, we reserve the right to disclose any data we consider
          necessary or appropriate, including your profile information (i.e. name, email address,
          etc.), usage history, and content submitted to our services. Any third-party providers
          will be required by Hala to take all necessary steps to ensure that your data is kept
          secure and used in accordance with Hala’s Privacy Policy. However, we shall not be liable
          for any unauthorized use of your personal data by a third-party provider. However, if
          ordered by a court of law, Hala shall be constrained to disclose to the appropriate
          authorities the personal information of any individual User(s). External web sites, whose
          links may be incorporated in the Hala’s wallet application, are not under the control of
          Hala, and Hala shall not be held responsible for the content or privacy policies of any
          linked sites or any links containing other linked sites.
        </p>

        <h4>5. Hala Marketing Usage</h4>
        <p>
          Hala may send marketing information to its customers in relation to promotions that Hala
          is initiating from time to time, or service offerings that is customized and related to
          certain criteria of customers. Any third-party providers will not be allowed to use or
          market directly using your personal data in connection with other services they offer. If
          you receive any such marketing that you have not specifically requested, you should let us
          know to take proper action immediately. In general, Hala retains and stores all collected
          information including your personal data, as long as you keep your profile open, or as
          needed to provide you with our services. We will retain your data and keep your account
          open, unless you request to close your account. In some cases, we choose to retain certain
          data (e.g., insights about Services use) in a depersonalized or correlation mechanism.
        </p>

        <h4>6. Hala Data Retention</h4>

        <h4>7. Hala Customers Rights</h4>
        <p>
          Our customers have all the rights to access, control, change and close their Hala
          account(s). If they choose to close their Hala account, their personal information will
          generally stop being visible to others on our services, within 72 hours. Hala, generally
          will delete closed account data, within 90 days of account closure, except if the below
          circumstances apply:
        </p>

        <ul>
          <li>
            Hala may retain your personal data even after you have closed your account, in order to
            comply with local regulatory requirements; to meet with our legal obligations (including
            law enforcement requests); to maintain cyber security protection, or prevent fraud and
            abuse (e.g., if we have restricted your account for breach of our Hala policies); and to
            enforce Hala’s terms and conditions. Thus, Hala will retain de-personalized data after
            your account has been closed.
          </li>
          <li>
            Data that have shared with others or information of transactions to others will remain
            visible even after you close your account or delete the information from your own
            profile, noting that we do not control data that other members have copied out of our
            services.
          </li>
        </ul>

        <h4>8. Contact Us</h4>
        <p>
          If you have any inquiries regarding our services or you believe that you need to report
          violation to our privacy policy, please contact Hala customer service, available 24/7
          (Contact Number: 8003033311 or email:support@hala.com); or through the customer care unit
          in the wallet application by contacting one of our customer service representatives. Also,
          the customer will be contacted regarding his case within three (3) working days from the
          date of receipt, unless the customer confirms that the case has been handled by us.
        </p>

        <h4>9. Policy Changes & Updates</h4>
        <p>
          Hala reserves the right to update and revise the Privacy Policy, at any time, to ensure it
          complies with applicable laws and conforms to changes in business. You are advised to
          visit the web site of Hala continually in order to monitor changes in the policy, as the
          policy will be binding upon you in relation to the use of Hala’s services, including
          Hala’s wallet. By using Hala’s wallet, and/or by providing the required information, you
          hereby consent to the collection and use of the information disclosed in accordance with
          the Privacy Policy of Hala.
        </p>
      </div>
    </div>
  </div>
</section>