import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
})
export class FaqsComponent {
  constructor(private translateService: TranslateService) {}
  cards = {
    cardOne: {
      heading: 'for-merchants.faq.question',
      contentValidSaudiCommercialRegistration: 'for-merchants.faq.answer.step1',
      contentMinimumSixMonthsOfOperation: 'for-merchants.faq.answer.step2',
      contentMnimumThreeMonthsOfBeingHalaMerchant: 'for-merchants.faq.answer.step3',
      contentMinimumTurnover: 'for-merchants.faq.answer.step4',
      expanded: false,
    },

    otherCards: [
      //Genreal Questions START
      {
        heading: 'faq.question1',
        content: 'faq.answer1',
        expanded: false,
      },
      {
        heading: 'faq.question2',
        content: 'faq.answer2',
        expanded: false,
      },
      {
        heading: 'faq.question3',
        content: 'faq.answer3',
        expanded: false,
      },
      {
        heading: 'faq.question4',
        content: 'faq.answer4',
        expanded: false,
      },
      //Genreal Questions END
      {
        heading: 'for-merchants.faq.question1',
        content: 'for-merchants.faq.answer1',
        expanded: false,
      },
      {
        heading: 'for-merchants.faq.question2',
        content: 'for-merchants.faq.answer2',
        expanded: false,
      },
      {
        heading: 'for-merchants.faq.question3',
        content: 'for-merchants.faq.answer3',
        expanded: false,
      },
      {
        heading: 'for-merchants.faq.question4',
        content: 'for-merchants.faq.answer4',
        expanded: false,
      },
      {
        heading: 'for-merchants.faq.question5',
        content: 'for-merchants.faq.answer5',
        expanded: false,
      },
      {
        heading: 'for-merchants.faq.question6',
        content: 'for-merchants.faq.answer6',
        expanded: false,
      },
    ],
  };

  toggleCard(card: any) {
    card.expanded = !card.expanded;
  }
}
