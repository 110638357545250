import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './common/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LandingPageModule } from './pages/landing-page.module';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateComponent } from './common/utils/translate/translate.component';
import { SharedModule } from './common/shared/shared.module';
import { PopupComponent } from './components/dialogs/popup/popup.component';
import { WhoAreYouDialogComponent } from './components/dialogs/who-are-you/who-are-you.component';
import { ConsumerProtectionPdfDialogComponent } from './components/dialogs/consumer-protection-pdf-dialog/consumer-protection-pdf-dialog.component';
// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeApp(myInitializationService: TranslateComponent) {
  debugger;
  return () => myInitializationService.useLanguage('en-US');
}

@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    WhoAreYouDialogComponent,
    ConsumerProtectionPdfDialogComponent,
    ConsumerProtectionPdfDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatGridListModule,
    LandingPageModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useClass: CustomLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en-US',
      isolate: true,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.setupLanguageFont();

    // Subscribe to the onLangChange event to update font styles when the language changes
    this.translate.onLangChange.subscribe(() => {
      this.setupLanguageFont();
    });
  }

  private setupLanguageFont() {
    const currentLanguage = this.translate.currentLang;
    const fontLink = document.createElement('link');
    fontLink.rel = 'stylesheet';
    // fontLink.type = 'text/html';
    fontLink.type = 'text/scss';
    fontLink.href = `src/assets/scss/font-family/${currentLanguage}.scss`;

    document.head.appendChild(fontLink);
  }
}
