<section class="calculatefin"  triggerOnce="true">
  <div class="fin-content">
    <div class="subcontentleft">
      <div class="finhead">
        <span><img src="../../../../../assets/images/calculateicon.svg" alt="" /></span>
        <h3>{{ 'for-merchants.calculateFinance' | translate }}</h3>
      </div>
      <div class="inputandbtn">
        <form [formGroup]="calcAmountForm" (ngSubmit)="onCalculate()">
          <mat-label>{{ 'for-merchants.averageMonthlySalesLabel' | translate }}</mat-label>
          <div class="torow" >
            <mat-form-field appearance="outline">
              <div class="input" [attr.data-suffix]="'for-merchants.calculator.sar' | translate">
              <input
                formControlName="calculate"
                type="text"
                pattern="\d*"
                maxlength="6"
                id="calculate"
                [placeholder]="'for-merchants.pricePlaceHolder' | translate"
                matInput
              />
              </div>
              <mat-error *ngIf="checkError('calculate', 'min')">{{
                'for-merchants.minimumAmountError' | translate
              }}</mat-error>
              <mat-error *ngIf="checkError('calculate', 'max')">{{
                'for-merchants.maximumAmountError' | translate
              }}</mat-error>
            </mat-form-field>
            <div class="btn">
              <app-button
                [buttonType]="'submit'"
                buttonColor="#5B0E9F"
                (buttonClicked)="onCalculate()"
                buttonTextColor="#fff"
                buttonWidth="101px"
                borderRadius="8px"
                buttonHeight="75%"
                >{{ 'for-merchants.calculate' | translate }}</app-button
              >
            </div>
          </div>
        </form>
      </div>
      <div>
        <app-calculator
       
          *ngIf="eligible && calculateValue >= 8333 && calculateValue <= 800000"
          [receivedValue]="calculateValue"
        ></app-calculator>
      </div>
    </div>
    <!-- <ng-template #test>
            <div class="anotherSubContent">
           
            </div>
        </ng-template> -->

    <div class="texts">
      <h2>{{ 'for-merchants.transparentInterestRates' | translate }}</h2>
      <p>{{ 'for-merchants.financingSolution' | translate }}</p>
    </div>
  </div>
</section>
