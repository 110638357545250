<section class="Policy">
  <div class="doc">
    <div class="contents">
      <div class="sub-contents" dir="rtl">
        <h1>هلا للتمويل سياسة الخصوصية</h1>

        <h4>المقدمة</h4>
        <p>
          1. تضمن سياسة الخصوصية هذه (“سياسة الخصوصية“) سياسة هلا للتمويل فيما يتعلق بجمع البيانات
          الشخصية واستخدامها وحمايتها من خلال الموقع الالكتروني لهلا للتمويل (“الموقع/ الموقع
          الالكتروني”). وقد تقوم هلا للتمويل بتحديث سياسة الخصوصية من وقت لآخر وعليك أن تتأكد من أنك
          تقرأ أحدث اصدارتها وأن تقرأها بعناية رجاء. وستكون أي تعديلات على سياسة الخصوصية سارية
          المفعول من تاريخ نشرها على الموقع الالكتروني أو ربطها بواسطة رابط الكتروني مع الموقع.
        </p>

        <p>
          2. لغايات سياسة الخصوصية هذه، فإن استخدام “هلا للتمويل” أو “نحن” أو “لنا” يعني هلا للتمويل
          والشركة المالكة لها وفروعها وشركاتها التابعة وحلفائها وموظفيها ومستشاريها الدائمين
          والمتعاونين.
        </p>

        <p>
          3. إن لك كمستخدم للموقع مطلق الحرية في أن تزودنا أو أن لاتزودنا ببيانات شخصية، ولكن إن
          اخترت عدم مشاركة البيانات المطلوبة فقد لاتتمكن من استخدام أو زيارة بعض أقسام الموقع أو
          خصائصه. أما إذا قررت مشاركة معلوماتك الشخصية، فإنك بذلك تقبل وتوافق على ما ورد في سياسة
          الخصوصية هذه.
        </p>

        <p>
          4. يجب ان تعلم بأن استخدام الموقع الالكتروني لهلا للتمويل قد يتطلب استخدام مقدمي خدمات
          استضافة (هوستينج) واتصالات واطراف آخرين ذوي علاقة باستخدام الموقع أو الخدمات المقدمة فيه،
          وهؤلاء الأطراف قد يجمعون معلومات شخصية عنك تتضمن بياناتك الشخصية، وهاتفك، وموقعك ومعلومات
          أخرى، وأن جمع هذه المعلومات واستخدامها يخضع لسياسة الخصوصية الخاصة بإولئك الأطراف،
          ولاتعتبر هلا للتمويل مسؤولة عن سياسة الخصوصية لأي طرف ثالث.
        </p>

        <h4>I. طبيعة المعلومات التي يتم جمعها</h4>
        <p>
          1. تعني عبارة “بيانات شخصية” -لغايات سياسة الخصوصية هذه – أي معلومات تحدد شخصيتك أو يمكن
          استخدامها لتحديد شخصيتك آو للاتصال بك، مثل الاسم، والبريد الالكترني، والعنوان، ورقم
          الهاتف. لاتجمع هلا للتمويل أي بيانات شخصية لمستخدم الموقع إلا إذا ورد شيء صريح بخلاف ذلك.
          إن زيارتك للموقع ستسجل اسم النطاق الخاص بك ، والجهاز المضيف (سيرفر) له والذي سيشكل جزءا من
          عنوان بريدك الالكتروني و الصفحات التي زرتها. مثل هذه المعلومات ستستخدم لإعداد إحصائيات
          لعدد زوار الموقع ومؤشرات عامة عنهم.
        </p>

        <p>
          2. قد نطلب بيانات شخصية عند تعبئة النماذج الخاصة الالكترونية الخاصة بالخدمات أو العروض
          الترويجية، أو الاستبيانات. فقد تطلب النماذج الالكترونية بيانات شخصية مثل الاسم وعنوان
          البريد الالكتروني وعنوان العمل ورقم الهاتف وأي معلومات أخرى ذات علاقة. أية بيانات شخصية
          يتم جمعها من خلال النماذج الالكترونية سيخضع لآحكام سياسة الخصوصة وأحكام وشروط خاصة يجب أن
          توافق عليها عند تعتبئة النموذج. إذا وجد أن النموذج الالكتروني لايتضمن شروطا ً خاصة فإن
          سياسة الخصوصية والأحكام والشروط العامة لاستخدام الموقع تنطبق على البيانات المقدمة بموجب
          النموذج.
        </p>

        <p>
          3. قد تزودنا منصات التواصل الاجتماعي ببعض البيانات الشخصية، على سبيل المثال، عند استخدام
          معلومات الدخول لمنصة التواصل الاجتماعي لغايات الدخول إلى موقع هلا للتمويل الالكتروني، فإنه
          قد تتم مشاركة بعض المعلومات المتوفرة على المنصة معنا، ,التي قد تتضمن بياناتك شخصية
          المتوفرة في حسابك (بروفايلك) هناك. إضافة إلى ذلك، قد ترغب بإنشاء حساب (بروفايل) لدينا
          لإدارة التسهيلات التي تتمتع بها أو الدفعات او الخدمات المقدمة لك، وسيطلب منك في هذه الحالة
          اختيار “اسم مستخدم” و “كلمة سر” لحسابك الألكتروني في هلا للتمويل، وعليه يجب أن تعلم أن أي
          معلومات يتم تخزينها أو جمعها في حسابك الالكتروني لدى هلا للتمويل ستكون متاحة لكل من تشارك
          معهم اسم المستخدم وكلمة السر الخاصين بك، لهذا السبب، يرجى حمايتهما بعناية وعدم الافصاح
          عنهما لـ أو مشاركتهما مع أي شخص آخر.
        </p>

        <p>
          4. قد تستخدم هلا للتمويل خدمات دفع إلكتروني مقدمة من أطراف آخرين (“خدمة الدفع الالكتروني“)
          لاستلام الدفعات من خلال الموقع. إذا رغبت بإجراء عملية دفع من خلال الموقع، فإنه قد يتم
          توجيهك إلى صفحة الدفع الالكتروني التابعة لمقدم خدمة الدفع وليست تابعة لهلا للتمويل. إن أي
          بيانات شخصية يتم تقديمها من خلال صفحة الدفع الالكتروني سوف يتم جمعها من قبل مقدم خدمة
          الالكتروني وليس من قبلنا وستكون تلك البيانات خاضعة لسياسة الخصوصية الخاصة بمقدم خدمة الدفع
          الالكتروني وليس لسياسة الخصوصية هذه، ثم إن هلا للتمويل ليس لديها أي سيطرة على صفحة الدفع
          الخارجية وليست مسؤولة عن استخدام مقدم خدمة الدفع
        </p>

        <h4>II. استخدام البيانات الشخصية التي يتم جمعها</h4>

        <p>
          1. قد نستخدم البيانات الشخصية التي يتم جمعها من خلال الموقع لغايات تقديم المنتجات و
          الخدمات ، ولتزويدك بالمعلومات المناسبة عن منتجاتنا وخدماتنا التي تقوم بطلبها أو تبدي رغبة
          بها. كما أننا قد نستخدم بياناتك الشخصية ، من حين إلى آخر، لتزويدك بمعلومات عن المنتجات
          والبرامج والعروض الترويجية التي نعتقد أنك قد تكون مهتم بها. كما قد نستخدم بياناتك الشخصية
          لاحتياجاتنا التجارية الداخلية كتحليل المعلومات وتحريرها، ولغايات التغذية الراجعة، ودعم
          العملاء، وتطوير وتخصيص محتوى وتصميم موقعنا الالكتروني، ولإتمام الصفقات التجارية، وتطوير
          خطط التسويق والعروض الترويجية وموادها، ولغايات التحيليل الاحصائي وتحليل سلوك المستخدمين،
          وتطوير المنتجات، وإدارة الحساب الشخصي، وللوفاء بالمتطلبات النظامية القانونية كتحصيل
          الضرائب والتدقيق وغيره.
        </p>

        <p>
          2. إضافة إلى ذلك، تحتفظ هلا للتمويل بحقها في استخدام البيانات الشخصية لإرسال معلومات مهمة
          حول الموقع الالكتروني، حالة حسابك، التغييرات التي تطرأ على أتفاقية الاستخدام، أو سياسة
          الخصوصية، أو أي سياسات أخرى، اتفاقيات أو صفقات ذات علاقة باستخدامك للموقع. وحيث أن هذه
          المعلومات قد تكون مهمة جداً لاستخدامك الموقع، فإنه قد لايمكن وقف استلام مثل هذه المراسلات.
        </p>

        <p>
          3. أخيراً، تؤكد هلا للتمويل أنها لن تبيع بياناتك الشخصية أو تصرح بها لأي طرف ثالث إلا في
          الحالات المذكورة في سياسة الخصوصية هذه.
        </p>

        <h4>III. الافصاح عن البيانات الشخصية</h4>

        <p>1. قد نفصح عن بيانات الشخصية لأطراف أخرين إذا كان لدينا اعتقاد بمناسبة ذلك :</p>

        <p>
          ● (أ) للوفاء بما تتطلبة الأنظمة أو اللوائح أو القواعد ذات العلاقة بنشاط هلا للتمويل في
          المملكة العربية السعودية،
        </p>

        <p>● (ب) للوفاء بمتطلبات الاجراءات القانونية،</p>
        <p>● (ج) للرد على طلبات الجهات الحكومية والسلطات العامة،</p>
        <p>● (د) لتنفيذ أحكام و شروط استخدام الموقع</p>

        <p>● (هـ) لحماية عملياتنا التجارية أو عمليات الشركات الحليفة،</p>

        <p>
          ● (و) لحماية حقوق هلا للتمويل وحقوق حلفائها وحقوق الغير وحقوقك، وخصوصيتهم، وسلامتهم
          وممتلكاتهم،
        </p>
        <p>
          ● (ز) لتمكيننا من المطالبة بأية تعويضات أو إجراءات قانونية متاحة لنا أو لتقليص الأضرار
          التي قد نتكبدها.
        </p>

        <h4>IV. الروابط الالكترونية</h4>
        <p>
          1.قد يحتوي موقعنا الالكتروني على روابط لمواقع أخرى، وهذه الروابط ليست تحت سيطرة هلا
          للتمويل كما أننا لا نزكي أياً منهم، إذا قمت بتزويد تلك المواقع بأية بيانات شخصية فإن
          العملية التي تود تنفيذها ستنفذ من خلال الموقع الذي الذي ينقلك الرابط إليه، وسيتم جمع
          بياناتك الشخصية من خلال ذلك الموقع وستكون البيانات خاضعة لأحكام سياسة الحصوصية الخاصة بذلك
          الموقع. لذلك نوصي بأن تقرأ سياسة الخصوصية للمواقع الخارجية بعناية، وللتأكيد فإن سياسة
          الخصوصية لموقعنا لا تتطرق إلى ممارسات المواقع الخارجية المتعلقة بالبيانات والخصوصية بما في
          ذلك المواقع الخاصة بالشركات الحليفة التي لايتم إخضاعها بشكل صريح لأحكام سياسة الخصوصية
          هذه.
        </p>

        <h4>V. الكوكيز</h4>
        <p>
          1. الـ “كوكي” هو ملف نصي صغير يتم تخزينه على الحاسب الآلي الخاص بك، أو جهازك اللوحي، أو
          هاتفك عند زيارة موقع إلكتروني ما. ولمعرفة المزيد من المعلومات عن الـ “الكوكيز” يمكن البحث
          عنه على الانترنت أو زيارة موقع www.allaboutcookies.org. نحن نستخدم الكوكيز (وغيرها من
          التقنيات المماثلة) لإعطائك تجربة الكترونية أفضل، ولمراقبة أداء الموقع ولجعل الموقع أكثر
          مناسبة وتخصيصا لك. كما أن الكوكيز تجعل خدمات الالكترونية أسهل وتساعد على فهم كيفية استخدام
          الجمهور لموقعنا الالكتروني.
        </p>
        <p>
          2. إذا قمت بحذف الكوكيز الخاصة بهذا الموقع، فإننا لن نتذكر أي شيء عنك بما في ذلك تفضيلاتك
          المتعلقة بالكوكيز، وسوف يتم التعامل معك كزائر للمرة الأولى. سوف تكون قادراً على استخدام
          معظم خدماتنا الالكترونية، ولكن بعض هذه الخدمات قد لايعمل بالشكل الذي تتوقعه إذا لم تسمح
          لنا بتخزين الكوكيز و الوصول إليها في جهازك.
        </p>
        <h4>VI. مدة الاحتفاظ بالبيانات</h4>
        <p>
          1. سوف نحتفظ بالبيانات الشخصية لمدة اللازمة بشكل معقول لتحقيق الاهداف التي من أجلها تم جمع
          البيانات الشخصية كما ورد في سياسة الخصوصية هذه ، وبما يتوافق مع المددة المقررة أو المسموح
          بها بموجب النظام.
        </p>
        <h4>VII. لا ضمانات</h4>
        <p>
          1. اتخذت هلا للتمويل الاحتياطات اللازمة لحماية البيانات الشخصية داخل الشركة من الفقدان،
          وسوء الاستخدام، أو الوصول غير المصرح به لها، أو تعديلها أو تدميرها. ويرجى العلم أنه رغم
          حرصنا على توفير موقع الكتروني وتطبيقات آمنة وموثوقة للمستخدمين، إلا أننا لاندعي أن الموقع
          والتطبيقات ستكون آمنة (100%)، فإذا كان لديك سبب للاعتقاد بأن استخدامك للموقع لن يكون آمنا
          لك، فإننا نرجو إخطارنا بالمشكلة فوراً من خلال معلومات الاتصال المذكورة في أدناه.
        </p>
        <h4>VIII. الشكاوى</h4>
        <p>
          1. تلتزم هلا للتمويل بالتعامل مع الشكاوى بشكل سريع وفعال وعادل وسري، ونؤمن بأن مايردنا من
          العملاء سواء كان إيجابياً أو سلبياً سيشكل فرصة لتحسين معاييرنا، وسوف نكون سعداء لمساعدتك
          فيما يتعلق بأي ملاحظة أو تخوف أو قلق يثور لديك ونشجعك على أن تقدم شكوى باستخدام نموذج
          تقديم شكوى ولكن نرجو الاطلاع على سياسة معالجة الشكاوى قبل تقديم الشكوى.
        </p>
        <h4>IX. معلومات الاتصال</h4>
        <p>
          1. توجه الاستفسارات والملاحظات المتعلقة بهذه الأحكام والشروط هذه إلى الإدارة القانونية
          لشركة شركة هلا للتمويل - مساهمة مقفلة:
        </p>

        <p>هاتف: 8003033311</p>
        <p>البريد الإلكتروني: support@HalaFinancing.com</p>
        <p>
          2. إذا اخترت أن تخاطبنا بواسطة البريد الإلكتروني فيما يتعلق بالأحكام و الشروط فإننا نرجو
          من المستخدم أن يعنون رسالته في سطر الموضوع بـ "الأحكام و الشروط".
        </p>

        <p>
          3. كما ويوافق المستخدم أن توجه هلا للتمويل أية إخطارات متعلقة بهذه الأحكام والشروط بطريقة
          إلكترونية، ويعتبر الاخطار الإلكتروني مستلماً من قبل المرسل إليه ومنتجاً لآثاره من اليوم
          التالي لإرسال الإخطار الإلكتروني وبصرف النظر عن تاريخ استلامه ما لم يثبت وجود خلل فني أدى
          إلى تأخر التسليم، وذلك دون الإخلال بأحكام إرسال الإخطارات الإلكترونية عن طريق الجهات
          الرسمية كــ "أبشر".
        </p>
      </div>
    </div>
  </div>
</section>