// email-utils.ts

import { environment } from '../environments/environment';
import { formValuesInterface } from './types/form-value.interface';

const generateEmailBody = (values: formValuesInterface) => {
  const {inquiry } = values;
  return inquiry;
};

export const createEmailData = (values: formValuesInterface) => {
  const subject = `New ${values.serviceType} Request`;
  const body = generateEmailBody(values);

  return {
    customerEmail:values.email,
    subject,
    phone:values.phoneNumber,
    serviceType:values.serviceType,
    senderName: values.firstName,
  };
};
