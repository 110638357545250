<section class="risk-warning">
  <div class="contents">
    <h4>
      {{ 'for-investors.riskWarning' | translate }}
    </h4>
    <p>
      {{ 'for-investors.riskContent' | translate }}
    </p>
  </div>
</section>
