import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
})
export class CalculatorComponent {
  @Input() receivedValue: number = 0;

  eligibleAmount: number = 0;
  feesAmount: number = 0;
  flatRate: number = 0.22;

  monthlyInstallmentSixMonth: number = 0;
  monthlyInstallmentOneYear: number = 0;
  monthlyInstallmentTwoYear: number = 0;

  aprAmountSixMonth: number = 0;
  aprAmountOneYear: number = 0;
  aprAmountTwoYear: number = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedValue']) {
      this.calculateEligibleAmount();

      this.calculateMonthlyInstallmentSixMonth();
      this.calculateMonthlyInstallmentOneYear();
      this.calculateMonthlyInstallmentTwoYear();

      this.calculateAPRSixMonth();
      this.calculateAPOneYear();
      this.calculateAPRTwoYear();
    }
  }

  calculateEligibleAmount() {
    this.eligibleAmount = this.receivedValue * 12 * 0.25;
    this.feesAmount = +(this.eligibleAmount * 0.2 * 0.115).toFixed(2);
  }
  calculateMonthlyInstallmentSixMonth() {
    this.monthlyInstallmentSixMonth = +(
      (this.eligibleAmount * (1 + (this.flatRate / 12) * 6)) /
      6
    ).toFixed(2);
  }
  calculateMonthlyInstallmentOneYear() {
    this.monthlyInstallmentOneYear = +(
      (this.eligibleAmount * (1 + (this.flatRate / 12) * 12)) /
      12
    ).toFixed(2);
  }
  calculateMonthlyInstallmentTwoYear() {
    this.monthlyInstallmentTwoYear = +(
      (this.eligibleAmount * (1 + (this.flatRate / 12) * 24)) /
      24
    ).toFixed(2);
  }
  calculateAPRSixMonth() {
    this.aprAmountSixMonth = +(
      (Math.pow(
        this.calculateAPR(
          6,
          this.monthlyInstallmentSixMonth,
          -(this.eligibleAmount - this.feesAmount)
        ) + 1,
        12
      ) -
        1) *
      100
    ).toFixed(2);
  }
  calculateAPOneYear() {
    this.aprAmountOneYear = +(
      (Math.pow(
        this.calculateAPR(
          12,
          this.monthlyInstallmentOneYear,
          -(this.eligibleAmount - this.feesAmount)
        ) + 1,
        12
      ) -
        1) *
      100
    ).toFixed(2);
  }
  calculateAPRTwoYear() {
    this.aprAmountTwoYear = +(
      (Math.pow(
        this.calculateAPR(
          24,
          this.monthlyInstallmentTwoYear,
          -(this.eligibleAmount - this.feesAmount)
        ) + 1,
        12
      ) -
        1) *
      100
    ).toFixed(2);
  }
  placeholder: string = 'Ex: 1000000                                                          SAR';

  // This function is used to calculate the APR its equivalent to RATE function in excel
  calculateAPR(periods: number, payment: number, present: number) {
    const guess = 0.01;
    const future = 0;
    const type = 0;

    // Set maximum epsilon for end of iteration
    let epsMax = 1e-10;

    // Set maximum number of iterations
    let iterMax = 10;

    // Implement Newton's method
    let y,
      y0,
      y1,
      x0,
      x1 = 0,
      f = 0,
      i = 0;
    let rate = guess;
    if (Math.abs(rate) < epsMax) {
      y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
    } else {
      f = Math.exp(periods * Math.log(1 + rate));
      y = present * f + payment * (1 / rate + type) * (f - 1) + future;
    }
    y0 = present + payment * periods + future;
    y1 = present * f + payment * (1 / rate + type) * (f - 1) + future;
    i = x0 = 0;
    x1 = rate;
    while (Math.abs(y0 - y1) > epsMax && i < iterMax) {
      rate = (y1 * x0 - y0 * x1) / (y1 - y0);
      x0 = x1;
      x1 = rate;
      if (Math.abs(rate) < epsMax) {
        y = present * (1 + periods * rate) + payment * (1 + rate * type) * periods + future;
      } else {
        f = Math.exp(periods * Math.log(1 + rate));
        y = present * f + payment * (1 / rate + type) * (f - 1) + future;
      }
      y0 = y1;
      y1 = y;
      ++i;
    }
    return rate;
  }
}
