import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { contactUsPath } from '../utils/constants';
import { formInterface } from '../utils/types/form.interface';
import { formValuesInterface } from '../utils/types/form-value.interface';
import { createEmailData } from '../utils/email-utils';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private url: string = 'https://api.hala.com' + contactUsPath;
  private loanUrl: string =
    'https://api.hala.com/financing/api/v1/LoanOrigination/CreateHFLoanApplication';

  constructor(private http: HttpClient) {}

  postData(values: formValuesInterface): Observable<any> {
    const data = createEmailData(values);
    return this.http.post(this.url, data);
  }

  applyForLoan(values: any): Observable<any> {
    const { id, cr, phoneNumber, reason } = values;
    const data = {
      CreatedBy: id,
      loanReasonId: reason,
      cashierId: 0,
      ownerUserId: id,
      crNumber: cr,
      ownerMobileNumber: phoneNumber,
    };
    return this.http.post(this.loanUrl, data);
  }
}
