<section class="getapproved" >
  <div class="contetnt">
    <div class="detailtext">
      <h2>{{ 'for-merchants.getApproved' | translate }}</h2>
      <p>{{ 'for-merchants.getApprovedDesc' | translate }}</p>
    </div>
    <div class="subcontentright">
      <div class="steps">
        <div class="nums">
          <span [ngClass]="{ normal: !showTitle1, N01: showTitle1 }" (click)="showT1()" class="N1"
            >1</span
          >

          <mat-divider
            *ngIf="showTitle1 || !showTitle2"
            [ngClass]="{ divider2: !showTitle1, divider1: showTitle1 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>
          <mat-divider
            *ngIf="showTitle2"
            [ngClass]="{ divider2: !showTitle2, divider0: showTitle2 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>

          <span
            [ngClass]="
              { normal: !showTitle2, N02: showTitle2 } || { normal: !showTitle2, N02: showTitle2 }
            "
            (click)="showT2()"
            class="N2"
            >2</span
          >

          <mat-divider
            *ngIf="showTitle2 || showTitle1 || showTitle4"
            [ngClass]="{ divider2: !showTitle2, divider1: showTitle2 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>
          <mat-divider
            *ngIf="showTitle3"
            [ngClass]="{ divider2: !showTitle3, divider0: showTitle3 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>

          <span [ngClass]="{ normal: !showTitle3, N03: showTitle3 }" (click)="showT3()" class="N3"
            >3</span
          >

          <mat-divider
            *ngIf="showTitle3 || showTitle2 || showTitle1"
            [ngClass]="{ divider2: !showTitle3, divider1: showTitle3 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>
          <mat-divider
            *ngIf="showTitle4"
            [ngClass]="{ divider2: !showTitle4, divider1: showTitle4 }"
            [vertical]="isSmallScreen"
            class="divider2"
          ></mat-divider>

          <span [ngClass]="{ normal: !showTitle4, N04: showTitle4 }" (click)="showT4()" class="N4"
            >4</span
          >
        </div>
        <!-- <div class="nums" *ngFor="let step of steps; let i = index" (click)="showStep(i)">
                            <span
                                [ngClass]="step.active ? ['N' + step.number, 'N0' + step.number, 'showTile' + step.number] : ['N' + step.number, 'normal']">
                                {{ step.number }}</span>

                            <mat-divider [class]="'divider' + step.number"
                                [ngClass]="step.number === 1 && step.active ? 'divider1' : step.number === 2 && step.active ? ['divider0'] : step.number === 3 && step.active ? ['divider1'] : ['divider0','normal-divider'] "
                                [vertical]="isSmallScreen"></mat-divider>
                        </div> -->
      </div>

      <!-- <div *ngIf="!isSmallScreen; else small" class="stepsdetails">
                        <div *ngFor="let step of steps; let i = index"
                            [ngClass]="step.active ? ['title', 'active'] : 'normal'" (click)="showStep(i)">
                            <div [ngClass]="step.active ? ['T' + step.number + 'Detail', 'active'] : 'normal'"
                                class="T{{ step.number }}Detail">
                                <h2>{{ step.title }}</h2>
                                <p *ngIf="step.active">{{ step.description }}</p>
                            </div>
                        </div>
                    </div> -->

      <div *ngIf="!isSmallScreen; else small" class="stepsdetails">
        <div
          [ngClass]="{ normal: !showTitle1Detail, title: showTitle1Detail }"
          (click)="showT1()"
          class="title1"
        >
          <div
            [ngClass]="{ normal: !showTitle1Detail, TDetail: showTitle1Detail }"
            class="T1Detail"
          >
            <h2>{{ 'for-merchants.step1' | translate }}</h2>
            <p *ngIf="showTitle1Detail">{{ 'for-merchants.des1' | translate }}</p>
          </div>
        </div>
        <div
          [ngClass]="{ normal: !showTitle2Detail, title: showTitle2Detail }"
          (click)="showT2()"
          class="title2"
        >
          <div
            [ngClass]="{ normal: !showTitle2Detail, TDetail: showTitle2Detail }"
            class="T2Detail"
          >
            <h2>{{ 'for-merchants.step2' | translate }}</h2>
            <p *ngIf="showTitle2Detail">{{ 'for-merchants.des2' | translate }}</p>
          </div>
        </div>
        <div
          [ngClass]="{ normal: !showTitle3Detail, title: showTitle3Detail }"
          (click)="showT3()"
          class="title3"
        >
          <div
            [ngClass]="{ normal: !showTitle3Detail, TDetail: showTitle3Detail }"
            class="T3Detail"
          >
            <h2>{{ 'for-merchants.step3' | translate }}</h2>
            <p *ngIf="showTitle3Detail">{{ 'for-merchants.des3' | translate }}</p>
          </div>
        </div>
        <div
          [ngClass]="{ normal: !showTitle4Detail, title: showTitle4Detail }"
          (click)="showT4()"
          class="title4"
        >
          <div
            [ngClass]="{ normal: !showTitle4Detail, TDetail: showTitle4Detail }"
            class="T4Detail"
          >
            <h2>{{ 'for-merchants.step4' | translate }}</h2>
            <p *ngIf="showTitle4Detail">{{ 'for-merchants.des4' | translate }}</p>
          </div>
        </div>
      </div>

      <ng-template #small>
        <div class="small-screen-cards">
          <div
            [ngClass]="{ normal: !showTitle1Detail, smallScreentitle1: showTitle1Detail }"
            class="smallScreentitle1"
          >
            <div *ngIf="showTitle1Detail" class="detailtext">
              <h2 style="height: max-content; justify-content: center; align-items: center">
                {{ 'for-merchants.step1' | translate }}
              </h2>
              <p>{{ 'for-merchants.des1' | translate }}</p>
            </div>
          </div>
          <div
            [ngClass]="{ normal: !showTitle2Detail, smallScreentitle2: showTitle2Detail }"
            class="smallScreentitle2"
          >
            <div *ngIf="showTitle2Detail" class="detailtext">
              <h2 style="height: max-content; justify-content: center; align-items: center">
                {{ 'for-merchants.step2' | translate }}
              </h2>
              <p>{{ 'for-merchants.des2' | translate }}</p>
            </div>
          </div>
          <div
            [ngClass]="{ normal: !showTitle3Detail, smallScreentitle3: showTitle3Detail }"
            class="smallScreentitle3"
          >
            <div *ngIf="showTitle3Detail" class="detailtext">
              <h2 style="height: max-content; justify-content: center; align-items: center">
                {{ 'for-merchants.step3' | translate }}
              </h2>
              <p>{{ 'for-merchants.des3' | translate }}</p>
            </div>
          </div>
          <div
            [ngClass]="{ normal: !showTitle4Detail, smallScreentitle4: showTitle4Detail }"
            class="smallScreentitle4"
          >
            <div *ngIf="showTitle4Detail" class="detailtext">
              <h2 style="height: max-content; justify-content: center; align-items: center">
                {{ 'for-merchants.step4' | translate }}
              </h2>
              <p>{{ 'for-merchants.des4' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
