<section class="faqs" >
  <h2>{{ 'for-merchants.faq.title' | translate }}</h2>
  <div class="faqsdetails">
    <mat-accordion
      *ngFor="let card of cards.otherCards"
      [class.expanded]="card.expanded"
      (click)="toggleCard(card)"
    >
      <mat-expansion-panel [ngStyle]="{ height: card.expanded ? 'auto' : '36' }">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ card.heading | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          {{ card.content | translate }}
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
