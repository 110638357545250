<section class="Policy">
    <div class="doc">
        <div class="contents">
            <div class="sub-contents" dir="rtl">
                <h1>هلا للتمويل الأحكام والشروط </h1>

                <h4>المقدمة</h4>
                <p> 1. يتولى تشغيل منصة هلا للتمويل (يشار إليها فيما بعد بـ "منصة هلا للتمويل" او بـ "المنصة")، شركة هلا
                    للتمويل
                    ، وهي شركة مساهمة مغلقة تأسست بموجب أنظمة المملكة العربية السعودية، بسجل تجاري رقم (1010905700) ،
                    حاصلة على
                    رخصة
                    البيئة التشريعية من البنك المركزي السعودي لممارسة التمويل الجماعي بالدين رقم (xxxxxx/xxxxx)، ويقع
                    مقرها
                    الرئيسي في 6533شارع العليا العام – حي العليا الرياض 2609 - 12251 المملكة العربية السعودية. تسري هذه
                    الشروط
                    والأحكام العامة على جميع مستخدمي منصة هلا للتمويل (يشار اليهم لاحقاً بالإفراد "المستخدم" او بالجمع
                    "المستخدمين") وأي من الخدمات المقدمة عن طريق المنصة.

                <p> 2. يتعين على المستخدم قراءة الشروط والأحكام بعناية قبل استخدام المنصة حيث توضح هذه الشروط والأحكام
                    تفاصيل
                    الخدمة التي تقدمها هلا للتمويل وتحدد الالتزامات والحقوق المتبادلة بين هلا للتمويل ومستخدم المنصة، ان
                    استخدام
                    هذه المنصة يعني انه تم الاطلاع و فهم والموافقة والاقرار بكافة الاحكام والشروط التي يتضمنها، وفي حال
                    عدم
                    الموافقة على هذه الاحكام والشروط عندها يحظر على المستخدم الولوج الى المنصة واستعمالها.

                <p> 3. تحتفظ هلا للتمويل بحقها في تعديل الشروط والأحكام في أي وقت لأي أسباب نظامية أو رقابية أو أمنية أو
                    غيرها
                    حسب تقديرها المطلق، وسوف يتم الاشعار من خلال رسالة نصية إلى الهاتف المحمول المعتمد لدى شركة هلا
                    للتمويل
                    اضافة الى القنوات الموثقة الأخرى بهذا الخصوص بمدة لا تقل عن30 يوماً تسبق اتخاذ الإجراء اللازم. وتكون
                    تلك
                    التعديلات مُلزمةً كما نوصي المستخدم بزيارة المنصة من حين إلى آخر للتأكد من أنه على دراية بأحدث
                    التغييرات
                    التي قد تطرأ على الشروط والأحكام.

                <p> 4. تقرأ هذه الشروط والأحكام مع الشروط وقائمة الأسئلة الشائعة وسياسة الخصوصية المنشورة على المنصة. في
                    حالة
                    وجود أي استفسارات تتعلق بالمنصة أو بهذه الشروط والأحكام، يرجى التواصل معنا عبر البريد الإلكتروني
                    support@HalaFinancing.com.
                </p>

                <h4> I. نبذة عن منصة هلا للتمويل</h4>

                <p>
                    منصة هلا للتمويل هي منصة رقمية عبر الإنترنت تمكن المستخدمين من الوصول إلى العروض المقدمة من قبل
                    المنشآت
                    طالبة التمويل المدرجة على المنصة من خلال حملات استثمارية، والاستثمار فيها يكون على أساس اتفاقية
                    استثمار
                    منفصلة عن هذه الشروط والأحكام وتحدد فيها تفاصيل الفرصة الاستثمارية، ولهذا الغرض تنشر المنشآت طالبة
                    التمويل
                    معلوماتها عبر منصة هلا للتمويل. ويجدر الذكر على أن هلا للتمويل لا تتحمل أي مسؤولية عن مدى مصداقية
                    البيانات
                    التي تقدمها المنشآت طالبة التمويل.
                </p>

                <h4> II. معايير الأهلية للتسجيل</h4>
                <p>
                    1. للدخول إلى المنصة، يجب ألا يقل عمر المستخدم عن (18) ثمانية عشر عاماً بالتقويم الميلادي، الذي يقر
                    ويتعهد
                    بأنه يتمتع بكامل الأهلية المعتبرة نظاماً وشرعاً للدخول إلى هذه المنصة وأهلية وقدرة كافية للتعاقد،
                    وليس
                    محظورًا لاستخدام خدمات هلا للتمويل والحساب الاستثماري بموجب أنظمة المملكة العربية السعودية. كما يقر
                    المستخدم
                    بأنه سيُطلَب منه التسجيل بصفته مستثمراً (مشارك) أو طالب للتمويل (منشأة طالبة التمويل) ، وتحتفظ هلا
                    للتمويل
                    بحق رفض طلب المستخدم للتسجيل في حال تمت مخالفة المعايير والشروط المنصوص عليها. ويتعين عليه إتباع
                    إجراءات
                    التسجيل على المنصة لكي يصبح مشارك أو منشأة طالبة التمويل حسب الشروط المطلوبة لأيٍ منهما، بالإضافة
                    إلى إنشاء
                    اسم مستخدم وكلمة مرور لاستخدامهما في الدخول إلى المنصة، وإتباع جميع المتطلبات والإجراءات الموضوعة
                    للتسجيل.
                <p> 2. عند التسجيل لتصبح مستثمراً أو منشأة طالبة للتمويل، سيُطلَب من المستخدم تقديم معلومات مفصلة عنه
                    شخصياً أو
                    عن الشركة التي تُمثلها. ويحق لمنصة هلا للتمويل أن تطلب منه تقديم مُستندات إضافية بهدف استيفاء
                    متطلبات
                    التسجيل للاستثمار أو طلب التمويل. وسوف تقوم هلا للتمويل – في بعض الأحيان وعند الحاجة – بالتحقق من
                    طالب
                    التسجيل باستخدام خدمات التوثيق الحكومية على سبيل المثال خدمة "يقين" المقدمة من "علم". كما يقر
                    المستخدم
                    بالتزامه بتصحيح بياناته فورا في حال تغيرها أو إدخالها بشكل غير كامل أو غير صحيح.
                <p> 3. يضمن المستخدم ويقر أيضا بأن جميع المعلومات التي من المقرر تقديمها على النحو المذكور أعلاه هي
                    معلومات
                    صحيحة وحقيقية. وتتحمل كمستخدم المسؤولية الكاملة في حال إدخال بيانات غير صحيحة خلال عملية التسجيل أو
                    خلال أي
                    مرحلة من مراحل استخدام المنصة.
                </p>

                <h4> III. الترخيص باستخدام المنصة</h4>
                <p>
                    ما لم يرد نص بخلاف ذلك، فإن هلا للتمويل تملك جميع حقوق الملكية الفكرية المتعلقة بهذه المنصة وأية
                    مواد منشورة
                    عليه. يمكن للمستخدم الاطلاع أو تنزيل المحتوى لغاية الرجوع إليه فيما بعد فقط، كما يمكنه طباعة صفحات
                    من المنصة
                    لاستخدامه الشخصي فقط مع مراعاة أي قيود أو ضوابط واردة في هذه الأحكام والشروط. لا يجوز لأي مستخدم
                    للمنصة:
                <p> ● نشر أي مواد من هذه المنصة (بما في ذلك إعادة النشر في مواقع أو صفحات أخرى) قبل تحصيل إذن خطي من هلا
                    للتمويل.
                    للتمويل .
                <p> ● بيع أو تأجير أو ترخيص استعمال أي مواد من هذه المنصة.
                <p> ● عمل عرض عمل أي من مواد هذه المنصة.
                <p> ● إعادة إنتاج، إنتاج نسخاً مزدوجة، إقامة صور ضوئية أو استخدام أية مواد من هذه المنصة لغايات تجارية،
                    أو أي
                    غاية غير للقيام باستثمار تمويلي أو الاستحصال على استثمار تمويلي.
                <p> ● تحرير أو تعديل بأي طريقة كانت أي من محتويات هذه المنصة.
                <p> ● إعادة توزيع أي مواد من هذه المنصة ما لم تكن تلك المواد مخصصة لإعادة التوزيع.
                <p> ● ويراعى أنه عندما يكون المحتوى معداً لإعادة التوزيع فإنه يجب أن يوزع فقط وفقاً لهذه الأحكام
                    والشروط.
                </p>

                <h4> IV. الاستخدام الغير المسموح به</h4>
                <p> 1. لا يجوز للمستخدم استخدام هذه المنصة بأي شكل يسبب أو قد يسبب أضراراً للمنصة أو يؤثر على توفرها أو
                    الوصول
                    إليها، أو بأي شكل غير مشروع أو غير قانوني أو ينطوي على احتيال أو إضرار أو يتصل بأي نشاط غير مشروع أو
                    غير
                    قانوني أو ينطوي على احتيال أو لأي هدف أو نشاط ضار.
                <p> 2. يجب أن لا تستخدم المنصة لنسخ أو تخزين أو استضافة أو نقل أو إرسال أو استخدام أو نشر أو توزيع أي
                    مواد تتضمن
                    (أو ترتبط بـ) أي برمجيات تجسس أو فيروسات أو أي برمجيات ضارة من أي نوع كانت.
                <p> 3. يجب أن يمتنع عن القيام بأي عملية إلكترونية أو برمجية لجمع المعلومات (على سبيل المثال لا الحصر،
                    عمليات
                    البحث العميق عن المعلومات او تجميع المعلومات أو استخلاصها بواسطة البرمجيات التجسسية أو البحثية
                    المتخصصة
                    والمتقدمة) من المنصة أو عنها بدون موافقة هلا للتمويل الخطية والصريحة.
                <p> 4. يجب أن لا يستخدم هذه المنصة لبث أو إرسال المراسلات التجارية غير المصرح بها خطيا من هلا للتمويل.
                <p> 5. يجب أن لا يستخدم هذه المنصة لأي غرض متعلق بالتسويق أو الترويج بدون موافق هلا للتمويل الخطية
                    والصريحة.
                </p>

                <h4> V. تفاصيل الرسوم مقابل الخدمة المقدمة</h4>
                <p> 1. يجب على مستخدم منصة هلا للتمويل دفع كافة الرسوم المطلوبة لاستخدام خدمات المنصة، ويتم دفع كافة
                    الرسوم عن
                    طريق قنوات الدفع المتاحة والمعتمدة في المنصة، وستقوم الشركة بإبلاغ المستخدم بالرسوم المطبقة، كما
                    يجوز لمنصة
                    هلا للتمويل تغيير رسومها في أي وقت، ويتم تقدير الرسوم وفقا لقيمة المعاملات شاملة لكافة الرسوم
                    المطبقة.
                <p> 2. يقع على عاتق طالب التمويل دفع رسوم إدارية مقابل الخدمات التي تقدمها هلا للتمويل؛ كعمليات العناية
                    الواجبة
                    والاستقصاء اللازم للتدقيق والتحقق من معلومات طالب التمويل قبل عملية التمويل الجماعي، وتحليل وتقييم
                    الوضع
                    المالي للمنشأة طالبة التمويل وقدرتها على السداد وغير ذلك من رسوم عن خدمات أخرى قد تطرأ لغرض تحقيق
                    العناية
                    اللازمة.
                <p> 3. في حال وجود أي استفسارات حول الخدمات المقدمة من هلا للتمويل فإنه يمكن التواصل مع منصة هلا للتمويل
                    عبر
                    البريد الإلكتروني التالي support@HalaFinancing.com
                </p>

                <h4> VI. الضمانات</h4>
                <p> 1. لا تضمن منصة هلا للتمويل بقاء المنصة مُتاحة للاستخدام بشكل مستمر ودون انقطاع نظرا إلى طبيعة عمل
                    الشبكة
                    العنكبوتية (الإنترنت). ولا تضمن بقاء المنصة أو أي جزء منها مُتاحاً للاستخدام أو عدم تعطلها أو خلوها
                    من
                    الأخطاء التقنية في أي وقت، ولا تضمن خلو المنصة من الفيروسات الحاسوبية أو أي مكونات ضارة أخرى، علماً
                    بأن هلا
                    للتمويل لا تتحمل أي مسؤولية بشأن الخسائر او اضرار تطال المستخدمين مهما كان شكلها او نوعها او طريقة
                    حصولها او
                    قيمتها خصوصاً تلك التي تقع نتيجة عدم إمكانية الدخول إلى المنصة أو أي جزء منها في أي وقت ولأي سبب.
                <p> 2. لا تقدم منصة هلا للتمويل أي ضمانات صريحة أو ضمنية أياً كان نوعها أو طبيعتها. كما لا تُقدّم أيضاً
                    هلا
                    للتمويل ولا أي من موظفيها ومديريها ووكلائها ولا أي من مقدمي الخدمات (مقدمي الخدمة بالنيابة عن منصة
                    هلا
                    للتمويل) أي ضمان بخصوص دقة أو صحة أي من المعلومات المنشورة على المنصة أو بشأن مصداقيتها. ولا تضمن
                    هلا
                    للتمويل للمستخدم مدى ملائمة أياً من الخدمات المُقدمة على المنصة للغرض المُحدد الذي تقصدُه. وبموجب
                    هذه الشروط
                    والأحكام، تؤكد هلا للتمويل عدم وجود أي ضمانات صريحة أو ضمنية حول كافة المعلومات والخدمات المقدمة على
                    المنصة
                    فيما عدا الضمانات التي لا يجوز استبعادها عملاً بأحكام الأنظمة المعمول بها في المملكة العربية
                    السعودية.
                <p> 3. ويقر المستخدم ويضمن كل من الآتي:
                <p> ● أنه مؤهل للتسجيل على منصة هلا للتمويل واستخدامها.
                <p> ● أن الاسم الذي حدده حين سجل حسابه هو اسمه أو الاسم التجاري المسجل في وزارة التجارة أو وزارة
                    الاستثمار.
                <p>● أنه لن يستخدم الحساب الاستثماري نيابة عن أي شخص طبيعي أو اعتباري (ان وجود تفويض في حال الشخص
                    الاعتباري هو
                    الزامي).
                <p> ● أنه يلتزم بجميع الأنظمة واللوائح المعمول بها في المملكة العربية السعودية.
                <p> ● أنه لن يستخدم خدمات هلا للتمويل في أي مشروع احتيالي أو بأي طريقة تتسبب في تعطيل استخدام المنصة.
                <p> ● أنه غير خاضع وغير مدرج على أي قائمة تتعلق بأطراف محظورة أو مقيدة وأنه غير مملوك أو خاضع لسيطرة أي
                    طرف من
                    تلك الأطراف، بما في ذلك على سبيل المثال لا الحصر: أي قوائم من قبل السلطات في المملكة العربية
                    السعودية،
                    القوائم التي يضعها مجلس الأمن التابع للأمم المتحدة، والحكومة الأمريكية (كقائمة المواطنين المحددة
                    أسماؤهم
                    بصفة خاصة وقائمة المتهربين من العقوبات الأجنبية الصادرة عن وزارة الخزانة الأمريكية وقائمة الكيانات
                    التابعة
                    لوزارة التجارة الأمريكية) والاتحاد الأوروبي أو دول الأعضاء أو السلطات الحكومية المنطبقة الأخرى.
                </p>

                <h4> VII. الاستثناءات </h4>
                <p> 1. لا يوجد شيء في هذه الأحكام والشروط من شأنه أن يستبعد أو يحدد أي ضمانات مقررة بموجب القانون الواجب
                    التطبيق
                    والتي يعتبر استبعادها أو تحديدها مخالفاً للقانون، كما أنه ليس هناك شيء فيها من شأنه أن يستبعد أو
                    يحدد
                    مسؤولية هلا للتمويل فيما يتعلق بأي:
                <p> ● وفاة أو ضرر شخصي نتج عن إهمال وتقصير هلا للتمويل.
                <p> ● الاحتيال أو الادعاءات الاحتيالية من قبل هلا للتمويل، أو
                <p> أي أمر يعتبر تحديد المسؤولية عنه أو استبعاده أو محاولة تحديده أو الإيهام بتحديده أو استبعاده من قبل
                    هلا
                    للتمويل مخالفاً للقانون.
                <p> 2. لا تتحمل هلا للتمويل أي مسؤولية إضافية على ما ذكر أعلاه وفي هذه الأحكام والشروط، منها الحالات
                    الآتية:
                    خصائص البضائع أو الأضرار المستحقة وفقا لحدود المسؤولية للمنتج أو الأضرار الناجمة عن فقدان الحياة أو
                    الإصابة
                    الجسدية أو الأضرار بالصحة.
                </p>

                <h4>حدود المسؤولية </h4>
                <p> 1. لن تكون منصة هلا للتمويل مسؤولة في مواجهة المستخدم (سواء بموجب أحكام المسؤولية العقدية، أو
                    المسؤولية
                    التقصيرية أو لأي سبب آخر) فيما يتعلق بمحتوى المنصة أو باستخدامها أو لأي سبب آخر يتعلق به، ويشمل ذلك:
                <p> ● الخسارة المباشرة، طالما أن استخدام المنصة متاح بدون مقابل.
                <p> ● أي خسائر غير مباشرة أو خاصة أو مترابطة.
                <p> ● أي خسائر للأعمال أو خسارة في الدخل، أو الأرباح أو التوفير، أو فوات العقود أو ضياع العلاقات
                    التجارية، أو أي
                    خسارة تتعلق بالسمعة أو الشهرة، أو أي خسارة أو تلف في المعلومات أو البيانات.
                <p> 2. كما ترفع مسؤولية هلا للتمويل في حال عدم اكتمال المبلغ التمويلي المطلوب خلال مدة الطرح، ويحق لها
                    إلغاء
                    الطرح وإعادة المبالغ المجمعة من المشاركين دون أدنى مسؤولية عليها ودون الحاجة إلى موافقتهم.
                <p> 3. في حالة وجود أي خلل في عملية التمويل، تتحمل هلا للتمويل مسؤولية اخطار العميل ومعالجة الخطأ
                    بالإضافة الى
                    تحمل الرسوم الناتجة عن الخطأ في الحالات التي يكون فيها الخلل ناتجاً عن المنصة او الإجراءات الداخلية
                    للشركة.
                </p>

                <h4> VIII. المخاطر الائتمانية واحتمالية التعثر في السداد</h4>
                <p> 1. يقر المستخدم ويوافق بمعرفته التامة بأن أي مُحتوى منشُور على المنصة لا يعتبر توصية أو مشورة بشأن
                    مدى
                    ملائمة أي فرصة تمويلية مطروحة على منصة هلا للتمويل. ولن تقوم هلا للتمويل ولا أي من مديريها أو
                    مسؤوليها أو
                    موظفيها أو أي مُزود خدمة من الغير بتقديم أي مشورة إلي المستخدم شخصياً بصورة مباشرة أو غير مباشرة
                    فيما يتعلق
                    بطبيعة أي تمويل أو معاملة أو بشأن قيمتها أو مدى ملائمتها له أو مدى إمكانية استعادة الأموال المستثمرة
                    والأرباح المتصلة بها. هلا للتمويل ليست مُخولة لتقديم أو منح أي مشورة بخصوص أي استثمار أو تمويل.
                <p> 2. كما يقر المستخدم بمعرفته التامة وموافقته بأن هذه المنصة لا يمنح ضمانات لعدم التعثر أو التخلف عن
                    السداد
                    للمنشأة طالبة التمويل وينصح بالاستعانة بمستشار مالي مختص بهذا النوع من التمويلات للحصول على مشورة
                    مستقلة قبل
                    اتخاذ أي قرار استثماري قد يؤدي إلى الخسارة الكاملة لاستثمارك. يجب ألا تستثمر الأموال التي لا يمكن
                    للمستخدم
                    تحمل خسارتها، وفي حال الخسارة يُقتصر على المبلغ المستثمر فقط.
                <p> 3. كما يقر المستخدم بالعلم بأن الاستثمار في الفرص التمويلية المدرجة على منصة هلا للتمويل ذات مخاطر
                    عالية وقد
                    يؤدي إلى الخسارة الكاملة للمبلغ المستثمر، فكلما ارتفع معدل الأرباح المتوقعة من الاستثمار كلما زاد
                    خطر
                    الخسارة.
                <p> 4. كما يقر المستخدم ويوافق على أن جميع التقييمات الائتمانية والبيانات المالية الأخرى المتاحة على
                    المنصة
                    مُجمعة من مصادر خارجية وأننا قمنا، حيثما أمكن، بالتحقق من تلك البيانات بقدر المستطاع من الناحية
                    التجارية لكن
                    لا تضمن هلا للتمويل بأي شكل من الأشكال مدى صحة أو دقة تلك المعلومات ولا تقر بأنها محدثة أو وافية أو
                    دقيقة،
                    وتخلي هلا للتمويل مسؤوليتها عن مدى مصداقية تلك المعلومات.
                </p>

                <h4> IX. وكيل التحصيل </h4>
                <p> 1. في حالة تعثر المنشأة المستفيدة (طالب التمويل) عن السداد أو عدم سداده لدفعة بموجب اتفاقية التورق،
                    ستقوم
                    منصة هلا للتمويل باتخاذ الخطوات اللازمة لمحاولة استرداد المبلغ المستحق، ويجوز أن تتضمن هذه الخطوات
                    إجراء
                    مناقشات مع المُمَوَّل (طالب التمويل) و/أو إعادة هيكلة التمويل بغرض محاولة استرداد المبالغ غير
                    المسددة
                    المستحقة منه.
                <p> 2. تدرك منصة هلا للتمويل بأن المنشأة المستفيدة (طالب التمويل) قد يخل بالتزاماته الخاصة بالسداد بموجب
                    اتفاقية
                    التورق ذي الصلة في حال مواجهته لمشكلة نقص في السيولة أو انخفاض في عوائد المبيعات أو وقوع حدث غير
                    متوقع.
                    وتحدد منصة هلا للتمويل بالنيابة عن المشارك (المستثمر) ما إذا كان ينبغي الموافقة على طلب المنشأة
                    المستفيدة
                    (طالب التمويل) بإعادة هيكلة التمويل من عدمه.
                <p> 3. إن وكيل التحصيل مفوض بموجب هذه الشروط والأحكام باتخاذ جميع الإجراءات المعقولة لحماية حقوق المشارك
                    (المستثمر)، والتي يجوز أن تتضمن التنفيذ على الكفالات، سواءً الشخصية أو التجارية، والسندات لأمر،
                    وأشكال
                    الضمان الأخرى، حسبما تقدمها المنشأة المستفيدة (طالب التمويل)، واتخاذ أي سبل لتصحيح الوضع أو إجراءات
                    أخرى حسب
                    المتاح من وقت إلى آخر بما يتفق والنظام المعمول به.
                <p> 4. في حال عدم قدرة منصة هلا للتمويل على استرداد أي مبالغ بموجب أي من الضمانات التي قدمتها المنشأة
                    المستفيدة
                    (طالب التمويل)، نلتزم بإحالة الدفعة التي تعثر عن سدادها المنشأة المستفيدة (طالب التمويل) إلى وكيل
                    التحصيل
                    الذي يلتزم بمحاولة تحصيل المبالغ المستحقة نيابةً عن المشارك (المستثمر). وتُخصَم التكاليف والمصروفات
                    القانونية والإدارية الخاصة بوكيل التحصيل من جميع المبالغ المستحقة من المنشأة المستفيدة (طالب
                    التمويل). تلتزم
                    منصة هلا للتمويل بإبلاغ المشارك (المستثمر) بجميع الإجراءات التي يتخذها وكيل التحصيل مع العلم بأن
                    منصة هلا
                    للتمويل لا تضمن له مدى نجاح أي إجراءات قضائية تُتخذ نيابةً عنه.

                <p> 5. يجوز لمنصة هلا للتمويل و وكيل التحصيل أن يتقاضوا أتعاب تحصيل من المنشأة المستفيدة (طالب التمويل)،
                    والتي
                    تُخصَم من المبلغ الكلي الذي يطالب بتحصيله واسترداده من المنشأة المستفيدة (طالب التمويل)، وتُخصَم
                    أتعاب
                    التحصيل هذه بالأولوية على أي توزيعات من المقرر سدادها للمشاركين (المستثمرين).
                <p> 6. يوافق المشارك (المستثمر) على أن التعامل مع المنشأة المستفيدة (طالب التمويل) وأي كفيل سيكون
                    مُقتصراً على
                    هلا للتمويل وعلى وكيل الضمان ووكيل التحصيل نيابةً عن المشارك (المستثمر)لاسترداد المبالغ المستحقة. لا
                    يجوز
                    للمشارك (المستثمر) اتخاذ أي إجراء فردي بهذا الشأن أو التعامل مع المنشأة المستفيدة (طالب التمويل) أو
                    الكفيل،
                    ما لم توافق هلا للتمويل على خلاف ذلك خطيّاً. تلتزم منصة هلا للتمويل بإبقاء المشارك (المستثمر) على
                    إطلاع
                    بالإجراءات المتخذة لتحصيل الدفعات التي تم التعثر عن سدادها واسترداد المبالغ المستحقة غير المسددة.
                </p>

                <h4> X. سياسة تضارب المصالح</h4>
                <p> 1. تعتبر حماية مصالح هلا للتمويل والمستثمرين والمنشآة طالبة التمويل أولوية وتقوم معها من خلال تنظيم
                    حالات
                    تعارض المصالح المحتملة والفعلية بين الشركة وأعضاء مجلس الإدارة وموظفي الشركة والمساهمين والمستثمرين.
                    وإن
                    تنظيم سياسة لتضارب المصالح هدفها أيضا تثبيت مفاهيم الشركة وقيمها ومبادئها المتمثلة في النزاهة والعمل
                    الجماعي
                    والعناية والمبادرة والإنجاز وتعزيز تلك القيم وحمايتها، وذلك لتفادي تأثير المصلحة الشخصية أو العائلية
                    أو
                    المهنية لأي شخص يعمل في الشركة على أداء واجباته اتجاه الشركة أو اتجاه المتعاملين معها. إضافة الى
                    ذلك، لتفادي
                    تأثير المصلحة الشخصية أو العائلية أو المهنية لأي شخص يعمل في هلا للتمويل على أداء واجباته تجاه هلا
                    للتمويل
                    أو تجاه المتعاملين مع هلا للتمويل.
                <p> 2. يقر المستخدم بأنه يجوز لهلا للتمويل، ولأي من شركاتها التابعة أو مسؤوليها أو مديريها أو موظفيها أو
                    وكلائها
                    أو أي شخص آخر تربطها بها علاقة أياً كان شكلها، امتلاك مصلحة مادية أو علاقة مادية بأي معاملة قد
                    تبرمها على
                    المنصة ويمكن أن ينشأ عنها تعارض مصالح. من المتفق عليه أن هلا للتمويل غير ملزمة إطلاقاً بالإفصاح عن
                    أي تعارض
                    مصالح من ذلك القبيل أو بتبرير أي علاقة مادية بأي معاملة، ما لم ينص النظام المعمول به صراحة على ذلك.
                    ومع ذلك،
                    تظل جميع الخدمات التي نتقدمها هلا للتمويل على هذه المنصة مُستقلة ودون تحيز أو تأثير وتقدم دون مراعاة
                    لأي
                    مصلحة مادية أو علاقة مادية من ذلك القبيل.
                </p>

                <h4> XI. الملكية الفكرية</h4>
                <p>
                    تملك شركة هلا للتمويل - مساهمة مقفلة "منصة هلا للتمويل" جميع حقوق ملكية المؤلف والعلامات التجارية
                    المسجلة
                    وغير المسجلة، وحقوق التصميمات، والتصميمات غير المسجلة، وحقوق قواعد البيانات الحالية والمستقبلية،
                    وكافة
                    المستندات والوثائق والمواد المتعلقة بالخدمة، وحقوق الملكية الفكرية الحالية والمستقبلية الموجودة في
                    المنصة.
                    فإنك كمستخدم توافق على اتخاذ جميع الإجراءات وتوقيع جميع المستندات اللازمة، في الحاضر أو في المستقبل،
                    حسبما
                    يطلب منك على نحو معقول، بهدف التنازل عن حقوق الملكية الفكرية المذكورة لصالح شركة هلا للتمويل
                    السعودية
                    للتمويل. وسوف تحتفظ شركة هلا للتمويل السعودية للتمويل بملكية جميع حقوق المؤلف المرتبطة بالبيانات
                    التي
                    تُحملها إلكترونياً أو تُقدمها إلى المنصة، وعليه فإنك كمستخدم تمنح شركة هلا للتمويل السعودية للتمويل
                    ترخيصاً
                    دائم وحصريّ ومن غير أي تعويضات أو مقابل وغير قابل للإنهاء في حال تم استخدام هذه البيانات ونسخها
                    وتوزيعها
                    ونشرها ونقلها بأي طريقة في جميع أنحاء العالم. في حالة تنزيل أي محتوى من المنصة فلا يعتبر ذلك منح حق
                    ملكية
                    لهذا المحتوى، كما لا يحق تعديل ذلك المحتوى أو استخدامه لأي أغراض أخرى ما عدا المنصوص عليها. وتظل
                    حقوق ملكية
                    المحتوى مملوكة بالكامل من قبل شركة هلا للتمويل السعودية للتمويل أو الجهات التي تقوم بالترخيص لها.
                </p>

                <h4> XII. مخالفة الشروط والاحكام</h4>
                <p>
                    من دون الإخلال بحقوق هلا للتمويل الأخرى المقررة بموجب هذه الأحكام و الشروط، إذا خالف المستخدم هذه
                    الأحكام
                    والشروط بأي شكل، فإن هلا للتمويل قد تتخذ أي إجراء قانوني مناسب وفقاً لتقدير هلا للتمويل للتعامل مع
                    المخالفة
                    التي قام بها المستخدم، بما في ذلك تعليق حسابه وحق دخوله إلى المنصة أو منعه من دخولها، أو حجب الأجهزة
                    التي
                    تستخدم عنوان المعرف (IP Address) الخاص به من دخول المنصة، والتواصل مع مزود الخدمة الخاص به لطلب حجب
                    دخوله
                    للمنصة أو اتخاذ أي إجراءات قضائية ضده.
                </p>

                <h4> XIII. أمن المعلومات وحماية البيانات </h4>
                <p>
                    1. تقوم هلا للتمويل بالمحافظة على سرية جميع المعلومات الشخصية والتجارية والمعلومات التي يتم الحصول
                    عليها من
                    طالبي التمويل (المنشآت طالبة التمويل) والمستثمرين (المشاركين) أو أي أشخاص آخرين بخصوص إبرام وإدارة
                    اتفاقيات
                    التمويل. كما تحتفظ هلا للتمويل بالبيانات الشخصية لتنفيذ الخدمة التي قام المستخدم بطلبها أو تلك التي
                    أذن
                    المستخدم بها. كما تولي هلا للتمويل أقصى درجات الأولوية لحماية البيانات الشخصية والتجارية كافة.
                <p> 2. يمنح المستخدم الموافقة الكاملة لهلا للتمويل عندما يقوم بإدراج البيانات في ملفه الشخصي على منصة
                    هلا
                    للتمويل، ويأذن لها باستخدام هذه البيانات وإتاحتها للمنشآت طالبة التمويل التي تعهد المستخدم الاستثمار
                    فيها،
                    وإتاحة تلك البيانات إلى أي شخص آخر حسب الضرورة.
                <p> 3. يمنح المستخدم الموافقة الكاملة لهلا للتمويل لجمع معلوماته وبياناته من اطراف خارجية ويأذن لها
                    بحفظها
                    ومشاركتها مع أي طرف آخر حسب الضرورة.
                <p> 4. يمنح المستخدم الموافقة الكاملة لهلا للتمويل لجمع معلوماته الإئتمانية عن طريق شركة سمة للمعلومات
                    الإئتمانية ويأذن لها بحفظها ومشاركة بياناته ومعلوماته وسلوكه الإئتماني مع الشركة السعودية للمعلومات
                    الائتمانية (سمة) أو أي جهة أخرى معتمدة من قبل البنك المركزي السعودي (SAMA).
                <p> 5. تتعهد المنشآت طالبة التمويل والمستثمرين بالحفاظ على سرية المعلومات والبيانات التي يقدمونها، كما
                    يتعهدون
                    بالحفاظ على سرية المعلومات والبيانات التي يتلقونها من أي طرف عبر المنصة. كما يتحمل مستخدمي المنصة من
                    مستثمرين أو منشآت طالبة التمويل أو من ينوب عنهم بذلك بشكل كامل مسؤولية جميع الأنشطة التي تتم بواسطة
                    إسم
                    المستخدم وكلمة المرور التي يقومون باستخدامها كما يجب على المستخدمين الالتزام بالتالي:
                <p> ● إبلاغ هلا للتمويل مباشرة عن اي استخدام غير مصرح به لأسم المستخدم أو كلمة المرور الخاص بهم أو أي
                    محاولة
                    لاختراق الحساب؛
                <p> ● يلزم التأكد من تسجيل الخروج في كل مرة يتم فيها الانتهاء من استخدام الحساب.
                <p> 6. يوافق المستخدم على منح منصة هلا للتمويل صلاحية الاطلاع والوصول واستخدام جميع بياناته عندما يقوم
                    بتخزينها
                    في ملفه الشخصي. وتقوم منصة هلا للتمويل ببذل كافة الجهود للحفاظ على سرية المعلومات وخصوصية البيانات.
                <p> 7. تقوم هلا للتمويل باتخاذ جميع التدابير الأمنية التقنية والتنظيمية لحماية المعلومات المتاحة لها
                    وتسهر على
                    عدم التلاعب والعبث بها، وحمايتها من الضياع أو التلف أو النفاذ إليها من قبل أشخاص غير مأذون لهم، وتتم
                    مراجعة
                    وتحديث التدابير الأمنية بشكل مستمر.
                <p> 8. تعتبر سياسة الخصوصية وسياسة ملفات الارتباط جزء لا يتجزأ من هذه الشروط والأحكام.
                <p> 9. منصة هلا للتمويل غير مسؤولة عن أي خسائر أو أضرار تحصل بسبب عدم الالتزام بهذه الشروط والأحكام.
                </p>

                <h4>XIV. الأطراف الأخرين </h4>
                <p>
                    يوافق المستخدم على التعهد بعدم تحريك أي مطالبة قانونية شخصية ضد مسؤولي هلا للتمويل وموظفيها عن أي
                    خسارة
                    تكبدتها فيما يتعلق باستخدام المنصة.
                <p> إن المستخدم يوافق على أن حدود المسؤولية وتقييدها الوارد في هذه الأحكام و الشروط سوف تنطبق و تحمي
                    مسؤولي هلا
                    للتمويل وموظفيها ووكلائها وشركاتها التابعة ومقاوليها وهلا للتمويل ذاتها.
                </p>

                <h4> XV. نقل الحقوق</h4>
                <p>
                    لهلا للتمويل حق نقل الحقوق المقررة بموجب هذه الأحكام و الشروط أو تحويلها أو التعاقد عليها من الباطن
                    والتصرف
                    بها بأي شكل دون الحاجة إلى إخطار المستخدم أو الحصول على موافقته بما لا يتعارض مع الأنظمة والقوانين
                    المعمول
                    بها في المملكة العربية السعودية. ولا يمكن للمستخدم نقل حقوقه المقررة بموجب هذه الأحكام والشروط أو
                    تحويلها أو
                    التعاقد عليها من الباطن أو التصرف بها بأي شكل.
                </p>

                <h4>القانون وتسوية المنازعات</h4>
                <p>
                    تخضع هذه الأحكام والشروط والنزاعات التي قد تنشب بشأنها للأنظمة المعمول بها في المملكة العربية
                    السعودية. وفي
                    حال نشوب أي نزاع بشأن هذه الأحكام والشروط أو تفسيرها أو تنفيذها فإن لجان المنازعات والمخالفات
                    التمويلية في
                    مدينة الرياض هي المختصة بنظره و الفصل فيه.
                </p>

                <h4>أحكام عامة</h4>
                <p>
                    1. يجوز لمنصة هلا للتمويل إسناد أي من التزاماتها أو خدماتها من خلال مقدمي الخدمات أو استبدال أي من
                    حقوقها و
                    التزاماتها أو التنازل عنها بموجب هذه الشروط العامة إلى طرف آخر حسب السلطة التقديرية المطلقة لمنصة
                    هلا
                    للتمويل.
                <p> 2. لا تُشكل أي ممارسة جزئية أو عدم ممارسة أو تأخير في ممارسة أي حق أو تعويض لمنصة هلا للتمويل تخلياً
                    منها عن
                    هذا الحق أو باقي الحقوق والتعويضات بموجب هذه الشروط العامة.
                <p> 3. يخضع التحاكم لهذه الشروط و الأحكام وأي التزامات غير تعاقدية ناشئة عنها أو تتعلق بها إلى الجهات
                    القضائية
                    في المملكة العربية السعودية.
                <p> 4. تكون سياسة الخصوصية المتبعة من منصة هلا للتمويل جزء لا يتجزأ من هذه الشروط والأحكام.
                <p> 5. في حال حكمت أي محكمة مختصة بعدم قانونية أو بطلان أو عدم قابلية تنفيذ أي حكم من أحكام هذه الشروط
                    والأحكام،
                    تظل باقي الأحكام والشروط بكامل القوة والأثر القانونيين.
                <p> 6. يستثنى من هذه الشروط والأحكام حالات القوة القاهرة الخارجة عن إرادة منصة هلا للتمويل أو الأطراف
                    المستخدمين
                    للمنصة ويشمل على سبيل المثال الكوارث الطبيعية و الحروب والأعمال التخريبية والتغييرات السياسية
                    المفاجئة، كما
                    تشمل – منعاً للالتباس – قيام أي جهة حكومية ذات صلاحية بمنع أو إيقاف أو إصدار لوائح أو قرارات يترتب
                    عليها
                    تعذر استمرارية الأعمال.
                <p> 7. يجب على المستخدم أن لا:
                <p> ● ينشر أي مواد من هذه المنصة (بما في ذلك إعادة النشر في مواقع أخرى) قبل الاستحصال على موافقة هلا
                    للتمويل
                    الخطية.
                <p> ● يبيع أو يؤجر أو يرخص من الباطن أي مواد من هذه المنصة.
                <p> ● يعرض عرضاً عاماً لأي من مواد هذه المنصة.
                <p> ● يعيد إنتاج، ينتج نسخاً مزدوجة، يصور ضوئياً أو يستخدم أية مواد من هذه المنصة لغايات تجارية.
                <p> ● يحرر أو يعدل بأي طريقة أي من محتويات هذه المنصة.
                <p> ● يعيد توزيع أي مواد من هذه المنصة ما لم تكن تلك المواد مخصصة لإعادة التوزيع.
                </p>

                <h4> XVI. معلومات الاتصال</h4>
                <p>
                    1. توجه الاستفسارات والملاحظات المتعلقة بهذه الأحكام والشروط هذه إلى الإدارة القانونية لشركة شركة
                    هلا
                    للتمويل - مساهمة مقفلة:
                <p> هاتف: 8003033311
                <p> البريد الإلكتروني: support@HalaFinancing.com
                <p> 2. إذا اخترت أن تخاطبنا بواسطة البريد الإلكتروني فيما يتعلق بالأحكام و الشروط فإننا نرجو من المستخدم
                    أن
                    يعنون رسالته في سطر الموضوع بـ "الأحكام و الشروط".
                <p> 3. كما ويوافق المستخدم أن توجه هلا للتمويل أية إخطارات متعلقة بهذه الأحكام والشروط بطريقة إلكترونية،
                    ويعتبر
                    الاخطار الإلكتروني مستلماً من قبل المرسل إليه ومنتجاً لآثاره من اليوم التالي لإرسال الإخطار
                    الإلكتروني وبصرف
                    النظر عن تاريخ استلامه ما لم يثبت وجود خلل فني أدى إلى تأخر التسليم، وذلك دون الإخلال بأحكام إرسال
                    الإخطارات
                    الإلكترونية عن طريق الجهات الرسمية كــ "أبشر".
                </p>
            </div>
        </div>
    </div>
</section>