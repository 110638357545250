<div mat-dialog-content>
    <a (click)="closeDialog()">{{
        "footer.close-btn" | translate
        }}</a>
    <div>
        <ngx-extended-pdf-viewer
            [src]="language === 'ar-SA' ? 'assets/ConsumerProtectionAr.pdf' : 'assets/ConsumerProtectionEn.pdf'"
            [useBrowserLocale]="true" [showHandToolButton]="false" [showToolbar]="true" [showZoomButtons]="true"
            [showSidebarButton]="false" [showDrawEditor]="false" [showTextEditor]="false" [showPrintButton]="false"
            [showHandToolButton]="false" [showSpreadButton]="false" [showPropertiesButton]="false"
            [showPagingButtons]="false" [showDownloadButton]="false"
            [showSecondaryToolbarButton]="false"></ngx-extended-pdf-viewer>
    </div>
</div>