import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConsumerProtectionPdfDialogComponent } from 'src/app/components/dialogs/consumer-protection-pdf-dialog/consumer-protection-pdf-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() footerTop: string | undefined;
  @Input() footerTopLg: string | undefined;
  @Input() footerTopMd: string | undefined;
  @Input() footerTopSm: string | undefined;

  constructor(public dialog: MatDialog) {}

  viewConsumerProtectionFile(lang: string) {
    this.dialog.open(ConsumerProtectionPdfDialogComponent, {
      data: {
        language: lang,
      },
      panelClass: 'custom-panel',
      backdropClass: 'backdropBackground',
    });
  }
}
