<div class="centered-content">
    <span (click)="onClose()">Close</span>
    <ng-container>
        <img src="../../assets/icons/success.svg" />
        <h1>{{'who-are-you.verify' | translate}}</h1>
        <div class="btn">
            <app-button [buttonType]="'submit'" buttonColor="#5b0ea0" buttonTextColor="#fff" buttonHeight="75%"
                buttonWidth="100%" title="Next" (btnClick)="onIAmInvestor()" link="./">
                <h3>{{'who-are-you.investor' | translate}}</h3>
            </app-button>
            <app-button [buttonType]="'submit'" buttonColor="#5b0ea0" buttonTextColor="#fff" buttonHeight="75%"
                buttonWidth="100%" title="Next" (btnClick)="onIAmMerchant()" link="./">
                <h3>{{'who-are-you.merchant' | translate}}</h3>
            </app-button>
        </div>
    </ng-container>
</div>