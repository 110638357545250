import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'src/app/common/services/settings.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { WhoAreYouDialogComponent } from 'src/app/components/dialogs/who-are-you/who-are-you.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-translate',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent implements OnInit {
  isSmallScreen = false;
  isDrawerOpen = false;

  langs = {
    'en-US': 'English',
    'ar-SA': 'العربية',
  };

  language = this.langs['en-US'];
  savedLanguage: any;

  @ViewChild('drawer') drawer: MatDrawer | any;
  @ViewChild('link') link: ElementRef | any;
  fullUrl: string = '';

  constructor(
    private translate: TranslateService,
    private settings: SettingsService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog
  ) {
    translate.addLangs(['en-US', 'ar-SA']);
  }

  ngOnInit(): void {
    this.fullUrl = window.location.href;

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      this.isSmallScreen = result.matches;
    });

    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && this.langs.hasOwnProperty(savedLanguage)) {
      this.language = this.getLanguageName(savedLanguage);
      this.translate.use(savedLanguage);
    } else {
      this.language = this.getLanguageName(this.translate.currentLang);
    }
  }

  toggleDrawer() {
    this.drawer.close();
    this.isDrawerOpen = !this.isDrawerOpen;
  }

  useLanguage(language: string) {
    this.settings.setLanguage(language);
    this.translate.use(language);
    this.savedLanguage = language;
    language == 'en-US' ? (language = this.langs['en-US']) : (language = this.langs['ar-SA']);
    this.language = language.valueOf();

    if (this.drawer) {
      this.drawer.close();
      this.isDrawerOpen = !this.isDrawerOpen;
    }
  }

  private getLanguageName(language: string): string {
    return language === 'ar-SA' ? 'English' : 'العربية';
  }

  changeLanguage() {
    const currentLanguage = this.language === 'العربية' ? 'en-US' : 'ar-SA';
    const newLanguage = currentLanguage === 'en-US' ? 'ar-SA' : 'en-US';
    this.translate.use(newLanguage);
    this.settings.setLanguage(newLanguage);
    this.language = this.getLanguageName(newLanguage);
    if (this.drawer) {
      this.drawer.close();
      this.isDrawerOpen = !this.isDrawerOpen;
      window.location.reload();
    }
    window.location.reload();
  }

  onLogin() {
    const targetUrl =
      this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
        ? 'https://owner-stg.halafinancing.com'
        : 'https://owner.halafinancing.com';

    window.open(targetUrl, '_blank');
  }

  onRegister() {
    debugger;
    if (this.router.url.includes('investor')) {
      const targetUrl =
        this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
          ? 'https://investor-registration-stg.halafinancing.com/welcome/investor'
          : 'https://investor-registration.halafinancing.com/welcome/investor';
      window.open(targetUrl, '_blank');
    } else if (this.router.url.includes('merchant')) {
      const targetUrl =
        this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
          ? 'https://investor-registration-stg.halafinancing.com/welcome/owner'
          : 'https://investor-registration.halafinancing.com/welcome/owner';
      window.open(targetUrl, '_blank');
    } else {
      this.dialog.open(WhoAreYouDialogComponent, {
        panelClass: 'custom-panel',
        backdropClass: 'backdropBackground',
      });
    }
  }

  // ngAfterViewInit() {
  //   // Add an event listener to the link
  //   this.link.nativeElement.addEventListener('click', () => {
  //     // Check if the link is for the current page
  //     if (this.link.nativeElement.href === window.location.href) {
  //       // Close the drawer
  //       this.drawer.close();
  //     }
  //   });
  // }
}
