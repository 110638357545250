import { AfterContentChecked, AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-for-investors',
  templateUrl: './for-investors.component.html',
  styleUrls: ['./for-investors.component.scss'],
})
export class ForInvestorsComponent implements OnInit, AfterContentInit, AfterContentChecked {
  isExpanded = false;
  language: string | any = 'en-US';
  @ViewChild('targetDiv') targetDiv: any;
  scrollToDiv() {
    if (this.targetDiv) {
      const targetSection = this.targetDiv.nativeElement;
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  constructor() {}

  ngOnInit(): void {
    this.language = localStorage.getItem('language');
  }

  ngAfterContentInit(): void {
    this.language = localStorage.getItem('language');
  }

  ngAfterContentChecked(): void {
    this.language = localStorage.getItem('language');
  }

  cards = [
    //Genreal Questions START
    {
      heading: 'faq.question1',
      content: 'faq.answer1',
      expanded: false,
    },
    {
      heading: 'faq.question2',
      content: 'faq.answer2',
      expanded: false,
    },
    {
      heading: 'faq.question3',
      content: 'faq.answer3',
      expanded: false,
    },
    {
      heading: 'faq.question4',
      content: 'faq.answer4',
      expanded: false,
    },
    //Genreal Questions END
    {
      heading: 'for-investors.faq.question1',
      content: 'for-investors.faq.answer1',
      expanded: false,
    },
    {
      heading: 'for-investors.faq.question2',
      content: 'for-investors.faq.answer2',
      expanded: false,
    },
    {
      heading: 'for-investors.faq.question3',
      content: 'for-investors.faq.answer3',
      expanded: false,
    },
    {
      heading: 'for-investors.faq.question4',
      content: 'for-investors.faq.answer4',
      expanded: false,
    },
  ];

  toggleCard(card: any) {
    card.expanded = !card.expanded;
  }

  getFlippedImageURL(): string {
    const imageFileName = 'bannerindivisual.svg';
    const cacheBuster = new Date().getTime(); // Generate a cache-busting timestamp
    const flippedImageURL = `../../../../../assets/images/${imageFileName}?v=${cacheBuster}`;
    return flippedImageURL;
  }
}
