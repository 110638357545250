import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponentRoutingModule } from './landing-page-routing.module';
import { SharedModule } from 'src/app/common/shared/shared.module';
import { ForMerchantsComponent } from './landing-page/for-merchants/for-merchants.component';
import { ForInvestorsComponent } from './landing-page/for-investors/for-investors.component';
import { CalculatorComponent } from './landing-page/for-merchants/sections/calculate-finance/calculator/calculator.component';
import { ContactUsComponent } from './landing-page/contact-us/contact-us.component';
import { ConsumerProtectionComponent } from './landing-page/consumer-protection/consumer-protection.component';
import { InvestorsRelationsComponent } from './landing-page/investors-relations/investors-relations.component';
import { RiskWarningComponent } from '../components/risk-warning/risk-warning.component';
import { TAndCMerchantsComponent } from '../layout/footer/legal/t-and-c-merchants/t-and-c-merchants.component';
import { TAndCInvestorsComponent } from '../layout/footer/legal/t-and-c-investors/t-and-c-investors.component';
import { PrivacyPolicyComponent } from '../layout/footer/legal/privacy-policy/privacy-policy.component';
import { RiskAssessmentComponent } from './landing-page/risk-assessment/risk-assessment.component';
import { CalculateFinanceComponent } from './landing-page/for-merchants/sections/calculate-finance/calculate-finance.component';
import { GetApprovedComponent } from './landing-page/for-merchants/sections/get-approved/get-approved.component';
import { WhyHalaFinancingComponent } from './landing-page/for-merchants/sections/why-hala-financing/why-hala-financing.component';
import { FaqsComponent } from './landing-page/for-merchants/sections/faqs/faqs.component';
import { RegistrationFormComponent } from './landing-page/for-investors/registration-form/registration-form.component';
import { ContactUsFormComponent } from './landing-page/contact-us/contact-us-form/contact-us-form.component';
import { EnglishLettersOnlyDirective } from '../common/directives/english-letters-only.directive';
import { NumberPatternDirective } from '../common/directives/numbers-plus-only.directive';
import { ApplyLoanFormComponent } from './landing-page/for-merchants/sections/apply-loan-form/apply-loan-form.component';
import { EnglishNumberPatternDirective } from '../common/directives/english-numbers-only.directive';

@NgModule({
  declarations: [
    ForMerchantsComponent,
    ForInvestorsComponent,
    CalculatorComponent,
    ConsumerProtectionComponent,
    ContactUsComponent,
    InvestorsRelationsComponent,
    RiskWarningComponent,
    TAndCMerchantsComponent,
    TAndCInvestorsComponent,
    PrivacyPolicyComponent,
    RiskAssessmentComponent,
    CalculateFinanceComponent,
    GetApprovedComponent,
    WhyHalaFinancingComponent,
    FaqsComponent,
    RegistrationFormComponent,
    ContactUsFormComponent,
    EnglishLettersOnlyDirective,
    EnglishNumberPatternDirective,
    NumberPatternDirective,
    ApplyLoanFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LandingPageComponentRoutingModule,
    SharedModule,
  ],
})
export class LandingPageModule {}
