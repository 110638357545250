<div class="header">
  <nav>
    <a href=""><img src="../../../assets/images/Hf_Logo_Main.svg" loading="lazy" alt="" routerLink="/"
        routerLinkActive="active" style="height: 44px" />
    </a>
    <div class="links">
      <a id="merchants" [routerLink]="['../merchant']" routerLinkActive="active" class="router-link">{{ 'nav.merchant' |
        translate }}</a>
      <p>|</p>
      <a id="investors" [routerLink]="['../investors']" routerLinkActive="active" class="router-link">{{ 'nav.investor'
        | translate }}</a>
      <p>|</p>
      <a id="contact-us" [routerLink]="['../contactus']" routerLinkActive="active" class="router-link">{{
        'nav.contactus' | translate }}</a>
    </div>
    <div class="langContiner">
      <button class="btn btn-primary" (click)="onLogin()">{{'nav.login' | translate}}</button>
      <hr class="vertical-line" />
      <button class="btn btn-primary" (click)="onRegister()">{{'nav.register' | translate}}</button>
      <hr class="vertical-line" />
      <app-translate></app-translate>
    </div>
  </nav>
</div>