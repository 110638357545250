import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[englishNumber]'
})
export class EnglishNumberPatternDirective {

    // Define the regex pattern for English numbers only
    private pattern = /^[0-9]*$/;
  
    constructor(private el: ElementRef) { }
  
    @HostListener('input', ['$event'])
    onInput(event: Event): void {
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const inputValue = inputElement.value;
  
      if (!this.pattern.test(inputValue)) {
        inputElement.value = inputValue.replace(/[^0-9]/g, ''); // Remove invalid characters
        event.preventDefault(); // Prevent the invalid input
      }
    }
  }