import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-who-are-you',
  templateUrl: './who-are-you.component.html',
  styleUrls: ['./who-are-you.component.scss'],
})
export class WhoAreYouDialogComponent {
  fullUrl: string = '';
  constructor(
    public dialogRef: MatDialogRef<WhoAreYouDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;

    this.fullUrl = window.location.href;
  }

  onClose() {
    this.dialogRef.close();
  }

  onIAmInvestor() {
    const targetUrl =
      this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
        ? 'https://investor-registration-stg.halafinancing.com/welcome/investor'
        : 'https://investor-registration.halafinancing.com/welcome/investor';

    window.open(targetUrl, '_blank');
    this.dialogRef.close();
  }

  onIAmMerchant() {
    const targetUrl =
      this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
        ? 'https://investor-registration-stg.halafinancing.com/welcome/owner'
        : 'https://investor-registration.halafinancing.com/welcome/owner';

    window.open(targetUrl, '_blank');
    this.dialogRef.close();
  }
}
