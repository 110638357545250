<div class="centered-content">
  <ng-container *ngIf="isSuccess; else errorTemplate">
    <img src="../../assets/icons/success.svg" />
    <h1>{{message || 'for-investors.areYouInterested.requestSubmitted' | translate}}</h1>

  </ng-container>
  <ng-template #errorTemplate>
    <img src="../../assets/icons/error.svg" />
    <h1>{{message || ('for-investors.areYouInterested.sthwentwrong' | translate)}} </h1>
  </ng-template>
</div>