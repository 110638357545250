import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-risk-assessment',
  templateUrl: './risk-assessment.component.html',
  styleUrls: ['./risk-assessment.component.scss'],
})
export class RiskAssessmentComponent implements OnInit {
  minAmount = 40000;
  eligible = false;
  isSmallScreen = false;

  showTitle1: boolean = false;
  showTitle1Detail: boolean = false;
  showTitle2: boolean = true;
  showTitle2Detail: boolean = true;
  showTitle3: boolean = false;
  showTitle3Detail: boolean = false;
  showTitle4: boolean = false;
  showTitle4Detail: boolean = false;

  isExpanded = false;

  cards = {
    cardOne: {
      heading: 'riskAssessment.questionOne',
      content: 'riskAssessment.answerParaOne',
      content1: 'riskAssessment.answerParaTwo',
      expanded: false,
    },

    otherCards: [
      {
        heading: 'riskAssessment.questionTwo',
        content: 'riskAssessment.answerTwo',
        expanded: false,
      },
    ],

    faqCards: {
      heading: 'riskAssessment.faqQuestionOne',
      content1: 'riskAssessment.faqAnswerOne',
      expanded: false,
    },

    otherFaqCards: [
      //Genreal Questions START
      {
        heading: 'riskAssessment.faqQuestionTwo',
        content: 'riskAssessment.faqAnswerTwo',
        expanded: false,
      },
      {
        heading: 'riskAssessment.faqQuestionThree',
        content: 'riskAssessment.faqAnswerThree',
        expanded: false,
      },
      {
        heading: 'riskAssessment.faqQuestionFour',
        content: 'riskAssessment.faqAnswerFour',
        expanded: false,
      },
    ],
  };

  constructor(private breakpointObserver: BreakpointObserver) {}
  public calcAmountForm: FormGroup | any;
  ngOnInit(): void {
    this.initForm();
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.isSmallScreen = result.matches;
    });
  }

  initForm() {
    let calculate = '';

    this.calcAmountForm = new UntypedFormGroup({
      calculate: new UntypedFormControl(calculate, [
        Validators.required,
        Validators.min(40000),
        // Validators.max(1000000),
      ]),
    });
  }

  get formControl() {
    return this.calcAmountForm.controls;
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.formControl[controlName].hasError(errorName);
  };

  toggleCard(card: any) {
    card.expanded = !card.expanded;
  }

  showT1() {
    this.showTitle1 = true;
    this.showTitle1Detail = true;
    this.showTitle2 = false;
    this.showTitle2Detail = false;
    this.showTitle3 = false;
    this.showTitle3Detail = false;
    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT2() {
    this.showTitle2 = true;
    this.showTitle2Detail = true;
    this.showTitle1 = false;
    this.showTitle1Detail = false;
    this.showTitle3 = false;
    this.showTitle3Detail = false;
    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT3() {
    this.showTitle3 = true;
    this.showTitle3Detail = true;
    this.showTitle2 = false;
    this.showTitle2Detail = false;
    this.showTitle1 = false;
    this.showTitle1Detail = false;
    this.showTitle4 = false;
    this.showTitle4Detail = false;
  }
  showT4() {
    this.showTitle4 = true;
    this.showTitle4Detail = true;
    this.showTitle3 = false;
    this.showTitle3Detail = false;
    this.showTitle2 = false;
    this.showTitle2Detail = false;
    this.showTitle1 = false;
    this.showTitle1Detail = false;
  }
}
