import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-consumer-protection-pdf-dialog',
  templateUrl: './consumer-protection-pdf-dialog.component.html',
  styleUrls: ['./consumer-protection-pdf-dialog.component.scss'],
})
export class ConsumerProtectionPdfDialogComponent {
  language: string = 'ar-SA';
  constructor(
    public dialogRef: MatDialogRef<ConsumerProtectionPdfDialogComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {}

  ngOnInit(): void {
    this.language = this.data.language;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
