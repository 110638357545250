<section class="registration">
  <div class="contents">
    <div class="header">
      <h2>{{ 'for-merchants.loan.apply' | translate }}</h2>
    </div>

    <div *ngIf="errorMessage" class="error">
      {{ 'contactus.form.errorMessage' | translate }}
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
      <div class="first-name">
        <label>{{ 'for-merchants.loan.id' | translate }}</label>
        <input matInput type="text" id="name-input" maxlength="10" formControlName="id"
          [ngClass]="language === 'en-US' ? 'name-en' : 'name-ar'"
          placeholder="{{ 'for-merchants.loan.id' | translate }}" englishNumber />
      </div>

      <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-merchants.loan.cr' | translate }}</label>
          <input matInput type="text" id="name--2" maxlength="20"
            [ngClass]="language === 'en-US' ? 'email-en' : 'email-ar'" formControlName="cr"
            placeholder="{{ 'for-merchants.loan.cr' | translate }}" englishNumber />
        </div>
        <div class="phone">
          <label>{{ 'for-investors.areYouInterested.phoneNumber' | translate }}</label>
          <input matInput type="text" id="name--3" formControlName="phoneNumber" maxlength="13"
            [ngClass]="language === 'en-US' ? 'phone-en' : 'phone-ar'"
            placeholder="{{ 'for-investors.areYouInterested.phone' | translate }}" numberCustompattern />
        </div>
      </div>
    </form>
    <div class="reason-container">
      <select class="reason" [(ngModel)]="selectedOption">
        <option value="" disabled>{{ 'for-merchants.loan.reason' | translate }}</option>
        <option *ngFor="let option of options" [value]="option.id"> {{ language === 'en-US' ? option.enName :
          option.arName }}
        </option>
      </select>
    </div>

    <app-button [buttonType]="'submit'" buttonColor="#5B0EA0" (btnClick)="onSubmit()" buttonTextColor="#FFFFFF"
      buttonHeight="100%" borderRadius="16px" buttonWidth="100%" title="Start Investing">
      <h3>{{ 'for-investors.areYouInterested.send' | translate }}</h3>
    </app-button>
  </div>
</section>