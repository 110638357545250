<!-- Displaying translated strings -->
<section
  class="mainwhy"

>
  <div class="whyhalafin">
    <div class="heads">
      <h2>{{ 'for-merchants.whyHala' | translate }}</h2>
      <p>{{ 'for-merchants.whyHalaAnswer' | translate }}</p>
    </div>
    <div class="reasonswhy">
      <div class="first-two-cards">
        <div class="card1">
          <img src="../../../../../assets/images/mony-note.svg" alt="" />
          <div class="details">
            <h4>{{ 'for-merchants.financeUpTo' | translate }}</h4>
            <h2>35%</h2>
            <p>{{ 'for-merchants.ofYourAnnualrevenue' | translate }}</p>
          </div>
        </div>
        <div class="card3">
          <img src="../../../../../assets/images/mony-note.svg" alt="" />
          <div class="details">
            <h4>{{ 'for-merchants.fundValueUpTo' | translate }}</h4>
            <h2>{{ 'for-merchants.upToNumber' | translate }}</h2>
            <p>{{ 'for-merchants.calculator.sar' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="second-two-cards">
        <div class="card2">
          <img src="../../../../../assets/images/mony-note.svg" alt="" />
          <div class="details">
            <h4>{{ 'for-merchants.fastDisbursement' | translate }}</h4>
            <h2>{{ 'for-merchants.days' | translate }}</h2>
            <p>{{ 'for-merchants.orLess' | translate }}</p>
          </div>
        </div>

        <div class="card4">
          <img src="../../../../../assets/images/mony-note.svg" alt="" />
          <div class="details">
            <h4>{{ 'for-merchants.rateStartingAt' | translate }}</h4>
            <h2>1.66%</h2>
            <p>{{ 'for-merchants.perMonth' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
