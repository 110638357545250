import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/common/shared/shared.module';
import { ForInvestorsComponent } from './landing-page/for-investors/for-investors.component';
import { ForMerchantsComponent } from './landing-page/for-merchants/for-merchants.component';
import { ConsumerProtectionComponent } from './landing-page/consumer-protection/consumer-protection.component';
import { ContactUsComponent } from './landing-page/contact-us/contact-us.component';
import { InvestorsRelationsComponent } from './landing-page/investors-relations/investors-relations.component';
import { TAndCMerchantsComponent } from '../layout/footer/legal/t-and-c-merchants/t-and-c-merchants.component';
import { TAndCInvestorsComponent } from '../layout/footer/legal/t-and-c-investors/t-and-c-investors.component';
import { PrivacyPolicyComponent } from '../layout/footer/legal/privacy-policy/privacy-policy.component';
import { RiskAssessmentComponent } from './landing-page/risk-assessment/risk-assessment.component';

const routes: Routes = [
  { path: '', redirectTo: 'merchant', pathMatch: 'full' },

  {
    path: 'merchant',
    component: ForMerchantsComponent,
  },
  {
    path: 'investors',
    component: ForInvestorsComponent,
  },
  {
    path: 'contactus',
    component: ContactUsComponent,
  },
  // {
  //   path: 'cosumerprot',
  //   component: ConsumerProtectionComponent,
  // },
  // {
  //   path: 'inverelations',
  //   component: InvestorsRelationsComponent,
  // },
  {
    path: 't&cmerchants',
    component: TAndCMerchantsComponent,
  },
  {
    path: 't&cinvestors',
    component: TAndCInvestorsComponent,
  },
  {
    path: 'privacypolicy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'howitworks',
    component: RiskAssessmentComponent,
  },
  {
    path: '**', // Wildcard route
    redirectTo: 'merchant',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule],
})
export class LandingPageComponentRoutingModule {}
