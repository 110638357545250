import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-calculate-finance',
  templateUrl: './calculate-finance.component.html',
})
export class CalculateFinanceComponent implements OnInit {
  public calcAmountForm: FormGroup | any;

  minAmount = 8333;
  maxAmount = 800000;
  eligible = false;

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    let calculate = '';

    this.calcAmountForm = new UntypedFormGroup({
      calculate: new UntypedFormControl(calculate, [
        Validators.required,
        Validators.min(this.minAmount),
        Validators.max(this.maxAmount),
        // Validators.max(1000000),
      ]),
    });
  }
  get formControl() {
    return this.calcAmountForm.controls;
  }

  onCalculate() {
    if (this.calcAmountForm.value.calculate > this.minAmount) {
      return (this.eligible = true);
    } else return (this.eligible = false);
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formControl[controlName].hasError(errorName);
  };

  get calculateValue(): number {
    return this.calcAmountForm.get('calculate').value;
  }
}
