<div class="content">
  <div class="banner">
    <div class="texts">
      <h2>{{ 'contactus.bannerHead' | translate }}</h2>
      <p>{{ 'contactus.bannerHeadPara' | translate }}</p>
    </div>
    <div class="contact-us-image">
      <img src="../../../../../assets/images/banner.svg" alt="" />
    </div>
  </div>

  <div class="contactus">
    <div class="subcontent">
      <div class="heads">
        <h2>{{ 'contactus.contact' | translate }}</h2>
        <p>{{ 'contactus.letUsKnow' | translate }}</p>
      </div>
      <div class="waystocontact">
        <div class="contacts">
          <div class="card1">
            <div class="cardcontents">
              <img src="../../../../../assets/images/contact-phone.svg" alt="" />
              <div class="details">
                <h2>
                  {{ 'contactus.callus' | translate }}
                </h2>
                <p>
                  {{ 'contactus.phoneno' | translate }}
                </p>
              </div>
            </div>
            <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
            <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" />
          </div>
          <!-- <div class="card2">
                            <div class="cardcontents">
                                <img src="../../../../../assets/images/contact-hala.svg" alt="">
                                <div class="details">
                                    <h2>
                                        {{ 'contactus.phoneno' | translate}}
                                    </h2>
                                    <p>
                                        {{'contactus.viachat' | translate}} </p>
                                </div>
                            </div>
                        </div> -->
          <div class="card3">
            <div class="cardcontents">
              <img src="../../../../../assets/images/contact-fb.svg" alt="" />
              <div class="details">
                <h2>
                  {{ 'contactus.fb' | translate }}
                </h2>
                <p>
                  {{ 'contactus.fbchat' | translate }}
                </p>
              </div>
            </div>
            <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
            <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" />
          </div>
        </div>
        <div class="contacts">
          <div class="card1">
            <div class="cardcontents">
              <img src="../../../../../assets/images/contact-email.svg" alt="" />
              <div class="details">
                <h2>
                  {{ 'contactus.ccare' | translate }}
                </h2>
                <p>
                  {{ 'contactus.email' | translate }}
                </p>
              </div>
            </div>
            <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
            <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" />
          </div>
          <div class="card2">
            <div class="cardcontents">
              <img src="../../../../../assets/images/contact-twitter.svg" alt="" />
              <div class="details">
                <h2>
                  {{ 'contactus.twitter' | translate }}
                </h2>
                <p>
                  {{ 'contactus.userid' | translate }}
                </p>
              </div>
            </div>
            <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
            <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" />
          </div>
        </div>
        <div class="contacts">
          <div class="insta">
            <div class="cardcontents">
              <img src="../../../../../assets/images/contact-insta.svg" alt="" />
              <div class="details">
                <h2>
                  {{ 'contactus.insta' | translate }}
                </h2>
                <p>
                  {{ 'contactus.name' | translate }}
                </p>
              </div>
            </div>
            <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
            <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-contact-us-form></app-contact-us-form>
</div>