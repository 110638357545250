import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appEnglishLettersOnly]'
})
export class EnglishLettersOnlyDirective {
  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const regex = /^[a-zA-Z\u0621-\u064A\s]*$/;

    // Check if the input value matches the allowed pattern
    if (!regex.test(inputValue)) {
      // If not, replace the input value with only the allowed characters
      inputElement.value = inputValue.replace(/[^a-zA-Z]/g, '');
      // Dispatch a new input event to ensure the form control is updated
      const inputEvent = new Event('input', { bubbles: true });
      inputElement.dispatchEvent(inputEvent);
    }
  }
}