import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/app/common/environments/environment';
import { ApiService } from 'src/app/common/services/api.service';
import { loanOptions } from 'src/app/common/utils/constants';
import { formInterface } from 'src/app/common/utils/types/form.interface';
import { PopupComponent } from 'src/app/components/dialogs/popup/popup.component';

@Component({
  selector: 'app-apply-for-loan-form',
  templateUrl: './apply-loan-form.component.html',
})
export class ApplyLoanFormComponent implements OnInit {
  language: string | any = 'en-US';
  registerForm: FormGroup;
  successMessage: boolean = false;
  errorMessage: boolean = false;
  apiErrorMessage: boolean = false;
  options = loanOptions;
  selectedOption: string = '';
  @Output() closePopup = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private formBuilder: FormBuilder
  ) {
    this.registerForm = this.formBuilder.group({
      id: ['', [Validators.required]],
      cr: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
    });
  }

  showThankYouMessage(isError: boolean, message: string): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: { isError, message }, // Pass the isError value to the dialog
      width: '80%', // Adjust as needed
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  ngOnInit(): void {
    this.language = localStorage.getItem('language');
  }

  onSubmit() {
    if (this.registerForm.valid && this.selectedOption) {
      this.errorMessage = false;
      this.sendData();
    } else {
      this.errorMessage = true;
    }
  }

  sendData() {
    const data = {
      ...this.registerForm.value,
      reason: this.selectedOption,
    };

    this.apiService.applyForLoan(data).subscribe({
      next: response => {
        this.successMessage = true;
        this.apiErrorMessage = false;
        this.registerForm.reset();
        this.selectedOption = '';
        this.closePopup.emit();
        this.showThankYouMessage(false, response);
      },
      error: error => {
        this.apiErrorMessage = true;
        this.showThankYouMessage(true, error?.error?.detail);
        // this.closePopup.emit()
        // Handle API call errors
      },
    });
  }
}
