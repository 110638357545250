<div class="footer">
  <div class="divider">
    <img src="../../../assets/images/Hf_Logo_Main.svg" alt="" style="width: 85px" />
    <div class="details">
      <div class="one">
        <p>{{ 'footer.companyDescription' | translate }}</p>
      </div>
      <div class="two">
        <h2>{{ 'footer.legal' | translate }}</h2>
        <a>{{ 'footer.consumerProtection' | translate }}&nbsp;
          <span (click)="viewConsumerProtectionFile('en-US')">(English</span>&nbsp;-&nbsp;<span
            (click)="viewConsumerProtectionFile('ar-SA')">عربي)</span></a>
        <a routerLink="/">{{ 'footer.investorRelations' | translate }}</a>
      </div>
      <div class="three">
        <!-- style="cursor: pointer;" routerLink="../contactus" -->
        <h2>{{ 'footer.contactUs' | translate }}</h2>
        <a>8003033311</a>
        <a>Support@halafinancing.com</a>
      </div>
      <div class="followus">
        <h2>{{ 'footer.followUs' | translate }}</h2>
        <div class="sociallogos">
          <a href="https://www.facebook.com/halafinancing"><img src="../../../assets/icons/facebook_icon.svg"
              alt="" /></a>
          <a href="https://twitter.com/halafinancing">
            <img src="../../../assets/icons/twitter_icon.svg" alt="" />
          </a>
          <a href="./">
            <img src="../../../assets/icons/linkedin_icon.svg" alt="" />
          </a>
          <a href="https://www.instagram.com/halafinancing">
            <img src="../../../assets/icons/instagram_icon.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright">
    <img src="../../../assets/icons/sharia-logo.svg" alt="" width="120" height="120" />
    <h3>{{ 'footer.copyright' | translate }}</h3>
    <h3>
      {{ 'footer.terms' | translate }} (
      <a routerLink="../t&cinvestors" routerLinkActive="active">
        {{ 'footer.merchant' | translate }}
      </a>
      -
      <a routerLink="../t&cinvestors" routerLinkActive="active">{{ 'footer.investor' | translate }} </a>) |
      <a routerLink="../privacypolicy" routerLinkActive="active">
        {{ 'footer.privacy' | translate }}
      </a>
    </h3>
  </div>
</div>