<button *ngIf="!isSmallScreen; else small" mat-icon-button class="matero-toolbar-button" (click)="changeLanguage()">
  <h3>
    {{ language }}
    <span>
      <img src="../../../../../assets/images/globalize.svg" alt="" />
    </span>
  </h3>
</button>

<ng-template #small>
  <mat-drawer class="fullscreen" #drawer>
    <!-- drawer content here -->
    <div class="drawercontents">
      <a class="link-animation" routerLink="../merchant" routerLinkActive="active" (click)="toggleDrawer()">{{
        'nav.merchant' | translate }}</a>
      <a class="link-animation" routerLink="../investors" routerLinkActive="active" (click)="toggleDrawer()">{{
        'nav.investor' | translate }}</a>
      <a class="link-animation" routerLink="../contactus" routerLinkActive="active" (click)="toggleDrawer()">{{
        'nav.contactus' | translate }}</a>
      <div class="langContiner">
        <button class="btn btn-primary" (click)="onLogin()">{{'nav.login' | translate}}</button>
        <hr class="vertical-line" />
        <button class="btn btn-primary" (click)="onRegister()">{{'nav.register' | translate}}</button>
      </div>
      <button mat-icon-button class="matero-toolbar-button" (click)="changeLanguage()">
        <h3>
          {{ language }}
          <span>
            <img src="../../../../../assets/images/globalize.svg" alt="" />
          </span>
        </h3>
      </button>
    </div>
  </mat-drawer>
  <button mat-icon-button (click)="drawer.toggle(); isDrawerOpen = !isDrawerOpen">
    <mat-icon>
      <img src="../../../../assets/icons/landing_menu.svg" alt="" *ngIf="!isDrawerOpen" />
      <img src="../../../../assets/icons/landing_menu_close.svg" alt="" *ngIf="isDrawerOpen" />
    </mat-icon>
  </button>
</ng-template>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of langs | keyvalue" (click)="useLanguage(lang.key)">
    <span style="font-family: Almarai">{{ lang.value }}</span>
  </button>
</mat-menu>