import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[numberCustompattern]'
})
export class NumberPatternDirective {

  // Define the regex pattern
  private pattern = /^[+\d]*$/;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    const inputValue = inputElement.value;

    if (!this.pattern.test(inputValue)) {
      inputElement.value = inputValue.replace(/[^+\d]/g, ''); // Remove invalid characters
      event.preventDefault(); // Prevent the invalid input
    }
  }
}
