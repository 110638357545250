import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-hala-financing',
  templateUrl: './why-hala-financing.component.html',
})
export class WhyHalaFinancingComponent implements OnInit {
  language: any = '';
  ngOnInit() {
    this.language = localStorage.getItem('language');
  }
}
