import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/common/material.module';
import { SmoothScrollDirective } from '../directives/smooth-scroll.directive';
import { AnimateDirective } from '../directives/animate.directive';
import { ButtonComponent } from 'src/app/components/form/buttons/button/button.component';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { FooterComponent } from 'src/app/layout/footer/footer.component';
import { TranslateComponent } from '../utils/translate/translate.component';
import { FormFieldComponent } from 'src/app/components/form/text-input/form-field/form-field.component';
import { ContactUsButtonsComponent } from 'src/app/components/form/buttons/contact-us-buttons/contact-us-buttons.component';
import { RouterModule } from '@angular/router';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    ButtonComponent,
    HeaderComponent,
    FooterComponent,
    TranslateComponent,
    FormFieldComponent,
    ContactUsButtonsComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CommonModule,
    TranslateModule,
    ButtonComponent,
    HeaderComponent,
    FooterComponent,
    TranslateComponent,
    ContactUsButtonsComponent,
    NgxExtendedPdfViewerModule,
  ],
  providers: [],
})
export class SharedModule {}
