<div class="content a">
  <div class="banner">
    <div class="texts" >
      <h2>{{ 'for-investors.bannerhead' | translate }}</h2>
      <p>{{ 'for-investors.bannerdetail' | translate }}</p>
    </div>
    <div class="image" >
      <img *ngIf="language === 'en-US'" class="en-img" src="../../../../../assets/images/bannerindivisual.svg"
        loading="lazy" alt="" />
      <img *ngIf="language === 'ar-SA'" class="ar-img" src="../../../../../assets/images/bannerindivisual.svg"
        loading="lazy" alt="" />
    </div>
  </div>

  <div class="mainwhy" >
    <div class="heads">
      <h2>{{ 'for-investors.whyHalaFinancing.title' | translate }}</h2>
      <p>{{ 'for-investors.whyHalaFinancing.multipleInvesmentOpprtunity' | translate }}</p>
    </div>
    <div class="whyhalafin">
      <div class="reasonswhy">
        <div class="firstreason">
          <div class="card0">
            <img src="../../../../../assets/images/why.svg" alt="" />
            <div class="details">
              <h2>{{ 'for-investors.whyHalaFinancing.diverseOpportunities' | translate }}</h2>
              <p>
                {{ 'for-investors.whyHalaFinancing.diverseOpportunitiesDescription' | translate }}
              </p>
            </div>
          </div>
          <div class="card1">
            <img src="../../../../../assets/images/why.svg" alt="" />
            <div class="details">
              <h2>
                {{ 'for-investors.whyHalaFinancing.flexibleInvestmentOptions' | translate }}
              </h2>
              <p>
                {{
                'for-investors.whyHalaFinancing.flexibleInvestmentOptionsDescription'
                | translate
                }}
              </p>
            </div>
          </div>
        </div>

        <div class="secondreason">
          <div class="card2">
            <img src="../../../../../assets/images/why.svg" alt="" />
            <div class="details">
              <h2>{{ 'for-investors.whyHalaFinancing.transparencyAndSecurity' | translate }}</h2>
              <p>
                {{
                'for-investors.whyHalaFinancing.transparencyAndSecurityDescription' | translate
                }}
              </p>
            </div>
          </div>
          <div class="card3">
            <img src="../../../../../assets/images/why.svg" alt="" />
            <div class="details">
              <h2>{{ 'for-investors.whyHalaFinancing.dedicatedSupport' | translate }}</h2>
              <p>
                {{ 'for-investors.whyHalaFinancing.dedicatedSupportDescription' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="image">
          <img src="../../../../../assets/images/iPhone 13 Pro Max 1.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div #targetDiv id="targetSection" >
    <app-registration-form></app-registration-form>
  </div>

  <div class="purple" >
    <div class="contents">
      <h2>{{ 'for-investors.whyHalaFinancing.reliableScoringEngine' | translate }}</h2>
      <p>{{ 'for-investors.whyHalaFinancing.reliableScoringEngineDescription' | translate }}</p>
    </div>
    <app-button [buttonType]="'submit'" buttonColor="#F3E5FF" buttonTextColor="#5B0EA0" buttonHeight="100%"
      borderRadius="16px" buttonWidth="100%" title="{{ 'for-investors.startInvesting' | translate }}"
      routerLink="../howitworks">
      <h3>{{ 'for-investors.whyHalaFinancing.checkCreditAssessment' | translate }}</h3>
    </app-button>
  </div>

  <div class="faqs" >
    <h2>{{ 'for-investors.faq.title' | translate }}</h2>
    <div class="faqsdetails">
      <mat-accordion *ngFor="let card of cards" [class.expanded]="card.expanded" (click)="toggleCard(card)">
        <mat-expansion-panel [ngStyle]="{ height: card.expanded ? 'auto' : '36' }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ card.heading | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            {{ card.content | translate }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <app-risk-warning ></app-risk-warning>
</div>