<div class="content">
  <section class="banner">
    <div class="texts">
      <h2>{{ 'riskAssessment.bannerhead' | translate }}</h2>
      <p>{{ 'riskAssessment.bannerpara' | translate }}</p>
    </div>
  </section>
  <section class="how-it-works" >
    <div class="header">
      <h2>{{ 'riskAssessment.howitworks' | translate }}</h2>
      <p>{{ 'riskAssessment.howitworkspara' | translate }}</p>
    </div>
    <div class="faqsdetails">
      <mat-accordion [class.expanded]="cards.cardOne.expanded" (click)="toggleCard(cards.cardOne)">
        <mat-expansion-panel [ngStyle]="{ height: cards.cardOne.expanded ? 'auto' : '36' }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ cards.cardOne.heading | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            {{ cards.cardOne.content | translate }}
            <br />
            {{ cards.cardOne.content1 | translate }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion *ngFor="let card of cards.otherCards" [class.expanded]="card.expanded" (click)="toggleCard(card)">
        <mat-expansion-panel [ngStyle]="{ height: card.expanded ? 'auto' : '36' }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ card.heading | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            {{ card.content | translate }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>

  <section class="mainwhy" >
    <div class="whyhalafin">
      <div class="heads">
        <h2>{{ 'riskAssessment.evaluation' | translate }}</h2>
        <p>{{ 'riskAssessment.evaluationPara' | translate }}</p>
      </div>
      <div class="reasonswhy">
        <div class="first-two-cards">
          <div class="card1">
            <img class="img" src="../../../../../assets/images/risk-assessmnt-img.svg" alt="" />
            <div class="details">
              <h4>
                {{ 'riskAssessment.numoftrans' | translate }}
              </h4>
              <p>
                {{ 'riskAssessment.numoftransPara' | translate }}
              </p>
            </div>
          </div>
          <div class="card2">
            <img src="../../../../../assets/images/risk-assessmnt-img.svg" alt="" />
            <div class="details">
              <h4>
                {{ 'riskAssessment.ageofowner' | translate }}
              </h4>
              <p>
                {{ 'riskAssessment.ageofownerPara' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="second-two-cards">
          <div class="card3">
            <img src="../../../../../assets/images/risk-assessmnt-img.svg" alt="" />
            <div class="details">
              <h4>
                {{ 'riskAssessment.entitycredithistory' | translate }}
              </h4>
              <p>
                {{ 'riskAssessment.entitycredithistoryPara' | translate }}
              </p>
            </div>
          </div>
          <div class="card4">
            <img src="../../../../../assets/images/risk-assessmnt-img.svg" alt="" />
            <div class="details">
              <h4>
                {{ 'riskAssessment.revenues' | translate }}
              </h4>
              <p>
                {{ 'riskAssessment.revenuesPara' | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="risk-catogs" >
    <div class="contetnt">
      <div class="detailtext">
        <h2>{{ 'riskAssessment.riskcatog' | translate }}</h2>
        <p>{{ 'riskAssessment.riskcatogPara' | translate }}</p>
      </div>
      <div class="subcontentright">
        <div class="steps">
          <div class="nums">
            <span [ngClass]="{ normal: !showTitle1, N01: showTitle1 }" (click)="showT1()" class="N1">{{
              'riskAssessment.A' | translate }}</span>

            <mat-divider *ngIf="showTitle1 || !showTitle2" [ngClass]="{ divider2: !showTitle1, divider1: showTitle1 }"
              [vertical]="isSmallScreen" class="divider2"></mat-divider>
            <mat-divider *ngIf="showTitle2" [ngClass]="{ divider2: !showTitle2, divider0: showTitle2 }"
              [vertical]="isSmallScreen" class="divider2"></mat-divider>

            <span [ngClass]="{ normal: !showTitle2, N02: showTitle2 }" (click)="showT2()" class="N2">{{
              'riskAssessment.B' | translate }}</span>

            <mat-divider *ngIf="showTitle2 || showTitle1 || showTitle4"
              [ngClass]="{ divider2: !showTitle2, divider1: showTitle2 }" [vertical]="isSmallScreen"
              class="divider2"></mat-divider>
            <mat-divider *ngIf="showTitle3" [ngClass]="{ divider2: !showTitle3, divider0: showTitle3 }"
              [vertical]="isSmallScreen" class="divider2"></mat-divider>

            <span [ngClass]="{ normal: !showTitle3, N03: showTitle3 }" (click)="showT3()" class="N3">{{
              'riskAssessment.C' | translate }}</span>

            <mat-divider *ngIf="showTitle3 || showTitle2 || showTitle1"
              [ngClass]="{ divider2: !showTitle3, divider1: showTitle3 }" [vertical]="isSmallScreen"
              class="divider2"></mat-divider>
            <mat-divider *ngIf="showTitle4" [ngClass]="{ divider2: !showTitle4, divider1: showTitle4 }"
              [vertical]="isSmallScreen" class="divider2"></mat-divider>

            <span [ngClass]="{ normal: !showTitle4, N04: showTitle4 }" (click)="showT4()" class="N4">{{
              'riskAssessment.D' | translate }}</span>
          </div>
        </div>

        <div *ngIf="!isSmallScreen; else small" class="stepsdetails">
          <div [ngClass]="{ normal: !showTitle1Detail, title: showTitle1Detail }" (click)="showT1()" class="title1">
            <div [ngClass]="{ normal: !showTitle1Detail, TDetail: showTitle1Detail }" class="T1Detail">
              <h2>{{ 'riskAssessment.catogA' | translate }}</h2>
              <p *ngIf="showTitle1Detail">{{ 'riskAssessment.catogAdetails' | translate }}</p>
            </div>
          </div>
          <div [ngClass]="{ normal: !showTitle2Detail, title: showTitle2Detail }" (click)="showT2()" class="title2">
            <div [ngClass]="{ normal: !showTitle2Detail, TDetail: showTitle2Detail }" class="T2Detail">
              <h2>{{ 'riskAssessment.catogB' | translate }}</h2>
              <p *ngIf="showTitle2Detail">{{ 'riskAssessment.catogBdetails' | translate }}</p>
            </div>
          </div>
          <div [ngClass]="{ normal: !showTitle3Detail, title: showTitle3Detail }" (click)="showT3()" class="title3">
            <div [ngClass]="{ normal: !showTitle3Detail, TDetail: showTitle3Detail }" class="T3Detail">
              <h2>{{ 'riskAssessment.catogC' | translate }}</h2>
              <p *ngIf="showTitle3Detail">{{ 'riskAssessment.catogCdetails' | translate }}</p>
            </div>
          </div>
          <div [ngClass]="{ normal: !showTitle4Detail, title: showTitle4Detail }" (click)="showT4()" class="title4">
            <div [ngClass]="{ normal: !showTitle4Detail, TDetail: showTitle4Detail }" class="T4Detail">
              <h2>{{ 'riskAssessment.catogD' | translate }}</h2>
              <p *ngIf="showTitle4Detail">{{ 'riskAssessment.catogDdetails' | translate }}</p>
            </div>
          </div>
        </div>

        <ng-template #small>
          <div class="small-screen-cards">
            <div [ngClass]="{ normal: !showTitle1Detail, smallScreentitle1: showTitle1Detail }"
              class="smallScreentitle1">
              <div *ngIf="showTitle1Detail" class="detailtext">
                <h2 style="height: max-content; justify-content: center; align-items: center">
                  {{ 'riskAssessment.catogA' | translate }}
                </h2>
                <p>{{ 'riskAssessment.catogAdetails' | translate }}</p>
              </div>
            </div>
            <div [ngClass]="{ normal: !showTitle2Detail, smallScreentitle2: showTitle2Detail }"
              class="smallScreentitle2">
              <div *ngIf="showTitle2Detail" class="detailtext">
                <h2 style="height: max-content; justify-content: center; align-items: center">
                  {{ 'riskAssessment.catogB' | translate }}
                </h2>
                <p>{{ 'riskAssessment.catogBdetails' | translate }}</p>
              </div>
            </div>
            <div [ngClass]="{ normal: !showTitle3Detail, smallScreentitle3: showTitle3Detail }"
              class="smallScreentitle3">
              <div *ngIf="showTitle3Detail" class="detailtext">
                <h2 style="height: max-content; justify-content: center; align-items: center">
                  {{ 'riskAssessment.catogC' | translate }}
                </h2>
                <p>{{ 'riskAssessment.catogCdetails' | translate }}</p>
              </div>
            </div>
            <div [ngClass]="{ normal: !showTitle4Detail, smallScreentitle4: showTitle4Detail }"
              class="smallScreentitle4">
              <div *ngIf="showTitle4Detail" class="detailtext">
                <h2 style="height: max-content; justify-content: center; align-items: center">
                  {{ 'riskAssessment.catogD' | translate }}
                </h2>
                <p>{{ 'riskAssessment.catogDdetails' | translate }}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </section>
  <section class="faqs" >
    <h2>{{ 'riskAssessment.faqs' | translate }}</h2>
    <div class="faqsdetails">
      <mat-accordion [class.expanded]="cards.faqCards.expanded" (click)="toggleCard(cards.faqCards)">
        <mat-expansion-panel [ngStyle]="{ height: cards.faqCards.expanded ? 'auto' : '36' }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ cards.faqCards.heading | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            <p>
              {{ cards.faqCards.content1 | translate }}
            </p>
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion *ngFor="let card of cards.otherFaqCards" [class.expanded]="card.expanded"
        (click)="toggleCard(card)">
        <mat-expansion-panel [ngStyle]="{ height: card.expanded ? 'auto' : '36' }">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ card.heading | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            {{ card.content | translate }}
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
  <app-risk-warning ></app-risk-warning>
</div>

<!-- <div
    style="width: 100%; height: 100vh; display: flex; margin: 0 auto; justify-content: center; align-items: center; gap: 4px; font-size: 35px; //font-family: 'Outfit';">
    <span style="color: #5B0E9F; font-size: 40px;">Hala, </span>
    <h2 style="
    height: auto; position: relative; margin: 0; font-size: 40px; //font-family: 'Outfit'; width: auto;">we
        are coming soon!</h2>
</div> -->