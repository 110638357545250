<section class="registration">
  <div class="contents">
    <div class="header">
      <h2>{{ 'for-investors.areYouInterested.title' | translate }}</h2>
      <p>{{ 'for-investors.areYouInterested.contactus24' | translate }}</p>
    </div>

    <div *ngIf="errorMessage" class="error">
      {{ 'contactus.form.errorMessage' | translate }}
    </div>

    <form ngNoForm id="__vtigerWebForm" name="1431" action="https://crm.bevatel.com/foody/modules/Webforms/capture.php"
      method="post" accept-charset="utf-8" target="logoutFrame" enctype="multipart/form-data"><input type="hidden"
        name="__vtrftk" value="sid:652669917cda12562d7ccf301956d4d2508dbc14,1707036835"><input type="hidden"
        name="publicid" value="7211d6f940f35f8289d96dc97bba6721"><input type="hidden" name="urlencodeenable"
        value="1"><input type="hidden" name="name" value="1431">
      <input id="name--3" type="hidden" name="ticket_title" data-label="" [value]="'Sales'">
      <input id="name--3" type="hidden" name="cf_1362" data-label="" [value]="'Sales'">
      <input type="hidden" name="cf_1368" [value]="language == 'en-US' ? 'English' : 'Arabic'">
      <input type="hidden" name="ticketpriorities" value="Normal">
      <input type="hidden" name="ticketstatus" value="Open">
      <input type="hidden" name="cf_1348" value="Select Category">
      <input type="hidden" name="cf_1350" value="Select Sub Category">
      <input type="hidden" name="description" value="Interested in investing">
      <input type="hidden" name="cf_1346" value="1111111111">

      <!-- prod -->

      <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.firstName' | translate }}<span style="color: red;">*</span></label>
          <input matInput id="name--3" id="name-input" [ngClass]="language === 'en-US' ? 'name-en' : 'name-ar'"
            placeholder="{{ 'for-investors.areYouInterested.name' | translate }}" appEnglishLettersOnly type="text"
            name="cf_1148" data-label="" value="" required>
        </div>
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.crNumber' | translate }}<span style="color: red;">*</span></label>
          <input type="text" minlength="10" maxlength="10"
            [ngClass]="language === 'en-US' ? 'cr-number-en' : 'cr-number-ar'"
            placeholder="{{ 'for-investors.areYouInterested.crNumberPlacehoder' | translate }}" numberCustompattern
            (input)="validateInputs($event)" (keydown)="allowOnlyNumbers($event)" name="cf_1352" data-label="" value=""
            required>
        </div>
      </div>
      <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.emailAdress' | translate }}<span
              style="color: red;">*</span></label>
          <input matInput maxlength="50" [ngClass]="language === 'en-US' ? 'email-en' : 'email-ar'"
            placeholder="{{ 'for-investors.areYouInterested.email' | translate }}" type="email" name="cf_1358"
            data-label="" value="" required>
        </div>
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.phoneNumber' | translate }}<span
              style="color: red;">*</span></label>
          <input matInput type="text" minLength="12" [ngClass]="language === 'en-US' ? 'phone-en' : 'phone-ar'"
            placeholder="{{ !phoneNumber ? ('for-investors.areYouInterested.phone' | translate) : '' }}"
            pattern="^\+966[0-9]{9,10}$" name="cf_1150" data-label="" [(ngModel)]="phoneNumber"
            (click)="setDefaultValue('phone')" (blur)="restorePlaceholder('phone')" (keydown)="handleBackspace($event)"
            (input)="handleAutofill($event)" #phoneInput="ngModel" required>
        </div>
      </div>
      <app-button [buttonType]="'submit'" (btnClick)="sendData()" buttonColor="#5B0EA0" buttonTextColor="#FFFFFF"
        buttonHeight="100%" borderRadius="16px" buttonWidth="100%" title="Start Investing">
        <h3>{{ 'for-investors.areYouInterested.send' | translate }}</h3>
      </app-button>
    </form>
  </div>
</section>
<iframe id="logoutFrame" #logoutFrame name="logoutFrame" style="display:none"></iframe>