import { Directionality } from '@angular/cdk/bidi';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/common/services/api.service';
import { PopupComponent } from 'src/app/components/dialogs/popup/popup.component';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
})
export class RegistrationFormComponent {
  language: string | any = 'en-US';
  successMessage: boolean = false;
  errorMessage: boolean = false;
  apiErrorMessage: boolean = false;
  serviceType: string = 'Sales Service';
  phoneNumber: string = '';
  nationalId: string = '';

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {}

  showThankYouMessage(isError: boolean, message: string): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      data: { isError, message }, // Pass the isError value to the dialog
      width: '50%', // Adjust as needed
      panelClass: 'custom-panel',
      backdropClass: 'backdropBackground',
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  ngOnInit(): void {
    this.language = localStorage.getItem('language');

    const logoutFrame = document.createElement('iFrame');
    logoutFrame.setAttribute('id', 'logoutFrame');
    logoutFrame.setAttribute('style', 'display:none');
    logoutFrame.setAttribute('src', 'https://crm.bevatel.com/foody/modules/Webforms/capture.php');
    document.body.appendChild(logoutFrame);
  }

  setDefaultValue(value: string): void {
    if (!this.phoneNumber && value == 'phone') {
      this.phoneNumber = '+966'; // Set default value only if empty
    }
  }

  restorePlaceholder(value: string): void {
    if (this.phoneNumber == '+966' && value == 'phone') {
      this.phoneNumber = ''; // Set default value only if empty
    }
  }

  handleBackspace(event: KeyboardEvent) {
    // Prevent backspace key from deleting the fixed part of the phone number
    if (event.key === 'Backspace' && this.phoneNumber === '+966') {
      event.preventDefault();
    }
  }

  allowOnlyNumbers(event: any) {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow special keys like backspace, delete, arrow keys, etc.
    if (
      charCode == 8 ||
      charCode == 9 ||
      charCode == 27 ||
      charCode == 46 ||
      (charCode >= 37 && charCode <= 40) ||
      (event.keyCode >= 96 && event.keyCode <= 105)
    ) {
      return;
    }
    // Allow only numbers
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  handleAutofill(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (!inputValue.startsWith('+966')) {
      this.phoneNumber = '+966';
    }
  }

  validateInputs(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length < 10) {
      event.target.setCustomValidity('Please enter a valid national ID number.');
    } else {
      event.target.setCustomValidity('');
    }
  }
  ngAfterContentInit(): void {
    this.language = localStorage.getItem('language');
  }

  ngAfterContentChecked(): void {
    this.language = localStorage.getItem('language');
  }

  sendData() {
    var form = document.getElementById('__vtigerWebForm') as HTMLFormElement,
      inputs = form?.elements;
    let isSubmitted = false;

    form.onsubmit = event => {
      event.preventDefault();
      var required: any = [],
        att,
        val,
        type;

      for (var i = 0; i < inputs.length; i++) {
        var inputElement = inputs[i] as HTMLInputElement;
        att = inputElement.getAttribute('required');
        val = inputElement.value;
        type = inputElement.type;
      }

      if (required.length > 0) {
        alert('The following fields are required: ' + required.join());
        return false;
      }

      form.submit();
      form.reset();
      this.phoneNumber = '';
      this.nationalId = '';
      isSubmitted = true;
      // return alert('Your request has been submitted, you will be notified shortly.');
      if (!isSubmitted) {
        return this.showThankYouMessage(
          true,
          this.translateService.instant('contactus.form.errorSubmitting')
        );
      } else {
        return this.showThankYouMessage(
          false,
          this.translateService.instant('contactus.form.requestSubmitted')
        );
      }
    };
  }
}
