<section class="registration">
  <div class="contents">
    <div class="header">
      <h2>{{ 'contactus.form.header' | translate }}</h2>
      <p>{{ 'contactus.form.para' | translate }}</p>
    </div>

    <div *ngIf="errorMessage" class="error">
      {{ 'contactus.form.errorMessage' | translate }}
    </div>
    <!-- -----------------------------------                  ------------------------------------------- -->
    <!-- -----------------------------------Customer Care Form------------------------------------------- -->
    <!-- -----------------------------------                  ------------------------------------------- -->

    <form ngNoForm id="__vtigerWebForm" name="1431" action="https://crm.bevatel.com/foody/modules/Webforms/capture.php"
      method="post" accept-charset="utf-8" target="logoutFrame" enctype="multipart/form-data"><input type="hidden"
        name="__vtrftk" value="sid:652669917cda12562d7ccf301956d4d2508dbc14,1707036835"><input type="hidden"
        name="publicid" value="7211d6f940f35f8289d96dc97bba6721"><input type="hidden" name="urlencodeenable"
        value="1"><input type="hidden" name="name" value="1431">
      <input id="name--3" type="hidden" name="ticket_title" data-label=""
        [value]="serviceType === 'Support Service' ? 'Service Support' : serviceType === 'Sales Service' ? 'Sales' : 'Complaint'">
      <input id="name--3" type="hidden" name="cf_1362" data-label=""
        [value]="serviceType === 'Support Service' ? 'Service Support' : serviceType === 'Sales Service' ? 'Sales' : 'Complaint'">
      <input type="hidden" name="cf_1368" [value]="language == 'en-US' ? 'English' : 'Arabic'">
      <input type="hidden" name="ticketpriorities" value="Normal">
      <input type="hidden" name="ticketstatus" value="Open">
      <input type="hidden" name="cf_1348" value="Select Category">
      <input type="hidden" name="cf_1350" value="Select Sub Category">
      <!-- prod -->

      <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.fullName' | translate }}<span style="color: red;">*</span></label>
          <input matInput id="name--3" id="name-input" [ngClass]="language === 'en-US' ? 'name-en' : 'name-ar'"
            placeholder="{{ 'for-investors.areYouInterested.name' | translate }}" appEnglishLettersOnly type="text"
            name="cf_1148" data-label="" value="" required>
        </div>
      </div>
      <div class="email-and-phone">
        <div class="phone">
          <label>{{ 'for-investors.areYouInterested.national_id_number' | translate }}<span
              style="color: red;">*</span></label>
          <input matInput type="text" minlength="10" maxlength="10"
            [ngClass]="language === 'en-US' ? 'national-id-number-en' : 'national-id-number-ar'"
            placeholder="{{ !nationalId ? ('for-investors.areYouInterested.nationalIdPlacehoder' | translate) : ''  }}"
            numberCustompattern (blur)="restorePlaceholder('nationalId')" (keydown)="allowOnlyNumbers($event)"
            (input)="validateInputs($event)" [(ngModel)]="nationalId" name="cf_1346" data-label="" value="" required>
        </div>
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.crNumber' | translate }}<span style="color: red;">*</span></label>
          <input type="text" minlength="10" maxlength="10"
            [ngClass]="language === 'en-US' ? 'cr-number-en' : 'cr-number-ar'"
            placeholder="{{ 'for-investors.areYouInterested.crNumberPlacehoder' | translate }}" numberCustompattern
            (input)="validateInputs($event)" (keydown)="allowOnlyNumbers($event)" name="cf_1352" data-label="" value=""
            required>
        </div>
      </div>
      <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.emailAdress' | translate }}<span
              style="color: red;">*</span></label>
          <input matInput maxlength="50" [ngClass]="language === 'en-US' ? 'email-en' : 'email-ar'"
            placeholder="{{ 'for-investors.areYouInterested.email' | translate }}" type="email" name="cf_1358"
            data-label="" value="" required>
        </div>
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.phoneNumber' | translate }}<span
              style="color: red;">*</span></label>
          <input matInput type="text" minLength="12" [ngClass]="language === 'en-US' ? 'phone-en' : 'phone-ar'"
            placeholder="{{ !phoneNumber ? ('for-investors.areYouInterested.phone' | translate) : '' }}"
            pattern="^\+966[0-9]{9,10}$" name="cf_1150" data-label="" [(ngModel)]="phoneNumber"
            (click)="setDefaultValue('phone')" (blur)="restorePlaceholder('phone')" (keydown)="handleBackspace($event)"
            (input)="handleAutofill($event)" #phoneInput="ngModel" required>
        </div>
      </div>

      <!-- <div class="email-and-phone">
        <div class="email">
          <label>{{ 'for-investors.areYouInterested.language' | translate }}</label>
          <mat-select placeholder="Select Language" id="name--3" id="name-input"
            [ngClass]="language === 'en-US' ? 'name-en' : 'name-ar'" name="cf_1368" data-label="label:Language">
            <mat-option value="Arabic">Arabic</mat-option>
            <mat-option value="English">English</mat-option>
          </mat-select>
        </div>
      </div> -->

      <div class="service-type">
        <label>{{ 'for-investors.areYouInterested.serviceType' | translate }}<span style="color: red;">*</span></label>
        <app-contact-us-buttons buttonColorOne="#FFF" buttonType="button" buttonColorTwo="#FFF" buttonColorThree="#FFF"
          [buttonTextColorOne]="serviceType === ('Support Service') ? '#8A24E6' : '#979797'"
          [buttonTextColorTwo]="serviceType === ('Sales Service') ? '#8A24E6' : '#979797'"
          [buttonTextColorThree]="serviceType === 'Complaint' ? '#8A24E6' : '#979797'" buttonHeight="100%"
          borderRadius="10px" buttonWidth="100%" title="" (btnOneClick)="setServiceType('2')"
          (btnTwoClick)="setServiceType('1')" (btnThreeClick)="setServiceType('3')"></app-contact-us-buttons>
      </div>

      <div class="inquiry">
        <label>{{ 'for-investors.areYouInterested.inquiry' | translate }}<span style="color: red;">*</span></label>
        <textarea id="inquiry" matInput type="text" name="description"
          [ngClass]="language === 'en-US' ? 'inquiry-en' : 'inquiry-ar'"
          placeholder="{{ 'for-investors.areYouInterested.inquiryPlaceholder' | translate }}" required></textarea>
      </div>
      <app-button [buttonType]="'submit'" (btnClick)="sendData()" buttonColor="#5B0EA0" buttonTextColor="#FFFFFF"
        buttonHeight="100%" borderRadius="16px" buttonWidth="100%" title="Start Investing">
        <h3>{{ 'for-investors.areYouInterested.send' | translate }}</h3>
      </app-button>
    </form>

    <!-- ==================================================================== -->

    <div [style.margin-top]="'100px'">
      <h2>{{ 'contactus.answerYouNeed' | translate }}</h2>
      <br />
      <app-button [buttonType]="'submit'" buttonColor="#5B0E9F" buttonTextColor="#FFFFFF" buttonHeight="100%"
        borderRadius="16px" buttonWidth="100%" (btnClick)="togglePopup()" title="">
        <h3>{{ 'contactus.esclate' | translate }}</h3>
      </app-button>
    </div>

    <div class="popup-overlay" *ngIf="isPopupVisible">
      <div class="popup">
        <div class="back" (click)="closePopup()">
          <!-- <img [src]="language === 'en-US' ? '/assets/icons/arrow-back.svg' : '/assets/icons/arrow_back_green_ar.svg'"
            alt=""> -->
          <a>{{'footer.close-btn' | translate}}</a>
        </div>
        <br />
        <h2>{{ 'contactus.esclate' | translate }}</h2>
        <p>{{ 'contactus.throughChannels' | translate }}</p>

        <div class="waystocontact">
          <div class="contacts">
            <div class="card1">
              <div class="cardcontents">
                <img src="../../../../../assets/images/contact-phone.svg" alt="" />
                <div class="details">
                  <h2>
                    {{ 'contactus.callus' | translate }}
                  </h2>
                  <p>
                    {{ 'contactus.phoneno' | translate }}
                  </p>
                </div>
              </div>
              <img *ngIf="language === 'en-US'"
                [src]="language === 'en-US' ? 'assets/icons/contact_us_arrow_en.svg' : 'assets/icons/contact_us_arrow_ar.svg'"
                alt="" />
              <!-- <img *ngIf="language === 'ar-SA'" src="'assets/icons/contact_us_arrow_ar.svg'" alt="" /> -->
            </div>

            <div class="card1">
              <div class="cardcontents">
                <img src="../../../../../assets/images/contact-email.svg" alt="" />
                <div class="details">
                  <h2>
                    {{ 'contactus.ccare' | translate }}
                  </h2>
                  <p>
                    {{ 'contactus.email' | translate }}
                  </p>
                </div>
              </div>
              <img *ngIf="language === 'en-US'"
                [src]="language === 'en-US' ? 'assets/icons/contact_us_arrow_en.svg' : 'assets/icons/contact_us_arrow_ar.svg'"
                alt="" />

              <!-- <img *ngIf="language === 'en-US'" src="../../../../../assets/icons/contact_us_arrow_en.svg" alt="" />
              <img *ngIf="language === 'ar-SA'" src="../../../../../assets/icons/contact_us_arrow_ar.svg" alt="" /> -->
            </div>
          </div>
        </div>
        <br />
        <p>
          {{ 'contactus.orSAMA' | translate }}
          <a style="text-decoration: none; color: #89908e;" href="https://con.samacares.sa/self-service/iamlogin">
            <span>{{ 'contactus.sAMACare' | translate }}</span>
          </a>
        </p>
      </div>
    </div>

    <!-- ==================================================================== -->

  </div>
</section><iframe id="logoutFrame" #logoutFrame name="logoutFrame" style="display:none"></iframe>