<div class="result">
  <h3>{{ 'for-merchants.calculator.eligble' | translate }}</h3>
  <div class="monthsdata">
    <div class="eachmonth">
      <h2>
        6 <span>{{ 'for-merchants.calculator.month' | translate }}</span>
      </h2>
      <h3>
        {{ eligibleAmount | number: '1.0' }}
        <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
      </h3>
      <div class="continer">
        <h2>{{ 'for-merchants.calculator.rate' | translate }}</h2>
        <h3>22%</h3>
        <h2>{{ 'for-merchants.calculator.apr' | translate }}</h2>
        <h3>{{ aprAmountSixMonth }}%</h3>
        <h2>{{ 'for-merchants.calculator.fees' | translate }}(2%)</h2>
        <h3>
          {{ feesAmount }} <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
        <h2>{{ 'for-merchants.calculator.installment' | translate }}</h2>
        <h3>
          {{ monthlyInstallmentSixMonth }}
          <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
      </div>
    </div>
    <div class="eachmonth">
      <h2>
        12 <span>{{ 'for-merchants.calculator.month' | translate }}</span>
      </h2>
      <h3>
        {{ eligibleAmount | number: '1.0' }}
        <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
      </h3>
      <div class="continer">
        <h2>{{ 'for-merchants.calculator.rate' | translate }}</h2>
        <h3>22%</h3>
        <h2>{{ 'for-merchants.calculator.apr' | translate }}</h2>
        <h3>{{ aprAmountOneYear }}%</h3>
        <h2>{{ 'for-merchants.calculator.fees' | translate }}(2%)</h2>
        <h3>
          {{ feesAmount }} <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
        <h2>{{ 'for-merchants.calculator.installment' | translate }}</h2>
        <h3>
          {{ monthlyInstallmentOneYear }}
          <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
      </div>
    </div>
    <div class="eachmonth">
      <h2>
        24 <span>{{ 'for-merchants.calculator.month' | translate }}</span>
      </h2>
      <h3>
        {{ eligibleAmount | number: '1.0' }}
        <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
      </h3>
      <div class="continer">
        <h2>{{ 'for-merchants.calculator.rate' | translate }}</h2>
        <h3>22%</h3>
        <h2>{{ 'for-merchants.calculator.apr' | translate }}</h2>
        <h3>{{ aprAmountTwoYear }}%</h3>
        <h2>{{ 'for-merchants.calculator.fees' | translate }}(2%)</h2>
        <h3>
          {{ feesAmount }} <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
        <h2>{{ 'for-merchants.calculator.installment' | translate }}</h2>
        <h3>
          {{ monthlyInstallmentTwoYear }}
          <span>{{ 'for-merchants.calculator.sar' | translate }}</span>
        </h3>
      </div>
    </div>
  </div>
  <p>
    {{ 'for-merchants.calculator.note' | translate }}
  </p>
</div>
