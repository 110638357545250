import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WhoAreYouDialogComponent } from 'src/app/components/dialogs/who-are-you/who-are-you.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() imgpath: string | any;
  @Input() alt: string | any;
  @Input() headerclass: boolean | any;
  @Input() buttonExpand: string | undefined;
  @Input() isActive: boolean = false;
  @Output() scrollEvent = new EventEmitter<void>();
  fullUrl: string = '';

  constructor(private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {}

  scrollToSection(value?: string) {
    this.scrollEvent.emit();
  }

  ngOnInit(): void {
    this.fullUrl = window.location.href;
  }

  onLogin() {
    const targetUrl =
      this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
        ? 'https://owner-stg.halafinancing.com'
        : 'https://owner.halafinancing.com';

    window.open(targetUrl, '_blank');
  }

  onRegister() {
    if (this.router.url.includes('investor')) {
      const targetUrl =
        this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
          ? 'https://investor-registration-stg.halafinancing.com/welcome/investor'
          : 'https://investor-registration.halafinancing.com/welcome/investor';
      window.open(targetUrl, '_blank');
    } else if (this.router.url.includes('merchant')) {
      const targetUrl =
        this.fullUrl.includes('-stg') || this.fullUrl.includes('localhost')
          ? 'https://investor-registration-stg.halafinancing.com/welcome/owner'
          : 'https://investor-registration.halafinancing.com/welcome/owner';
      window.open(targetUrl, '_blank');
    } else {
      this.dialog.open(WhoAreYouDialogComponent, {
        panelClass: 'custom-panel',
        backdropClass: 'backdropBackground',
      });
    }
  }
}
