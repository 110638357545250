import { environment } from "../environments/environment";

export interface AppSettings {
  navPos: 'side' | 'top';
  dir: 'ltr' | 'rtl';
  theme: 'light' | 'dark' | 'auto';
  showHeader: boolean;
  headerPos: 'fixed' | 'static' | 'above';
  showUserPanel: boolean;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  language: string;
  fonts: string;
}

export const defaults: AppSettings = {
  navPos: 'side',
  dir: 'ltr',
  theme: 'light',
  showHeader: true,
  headerPos: 'fixed',
  showUserPanel: true,
  sidenavOpened: true,
  sidenavCollapsed: false,
  language: 'en-US',
  fonts: 'Outfit',
};

export const loanOptions  = [
  {
    "arName": "شراء أصول",
    "enName": "Asset purchases",
    "name": null,
    "description": "Asset purchases",
    "id": "04e19500-0bbc-4696-8f8e-76a869759e90",
    "createdAt": "2023-05-18T12:57:39.618012",
    "deletedAt": null,
    "updatedAt": null,
    "updatedBy": null,
    "deletedBy": null,
    "createdBy": "SeederData"
  },
  {
    "arName": "تجارة جديدة / توسع",
    "enName": "New business line/expansion",
    "name": null,
    "description": "New business line/expansion",
    "id": "6ec57d41-bc9d-43dd-bf6f-606a286bb708",
    "createdAt": "2023-05-18T12:57:39.618012",
    "deletedAt": null,
    "updatedAt": null,
    "updatedBy": null,
    "deletedBy": null,
    "createdBy": "SeederData"
  },
  {
    "arName": "رأس المال العامل",
    "enName": "Working Capital",
    "name": null,
    "description": "Working Capital",
    "id": "735df164-9e6d-49a0-82a1-36eff7cbac90",
    "createdAt": "2023-05-18T12:57:39.617912",
    "deletedAt": null,
    "updatedAt": null,
    "updatedBy": null,
    "deletedBy": null,
    "createdBy": "SeederData"
  },
  {
    "arName": "تمويل فواتير",
    "enName": "Invoice Financing",
    "name": null,
    "description": "Invoice Financing",
    "id": "7c01f061-0ac9-41fc-ac15-693e3e3de8ac",
    "createdAt": "2023-05-18T12:57:39.618012",
    "deletedAt": null,
    "updatedAt": null,
    "updatedBy": null,
    "deletedBy": null,
    "createdBy": "SeederData"
  },
  {
    "arName": "استحواذ",
    "enName": "Acquisition ",
    "name": null,
    "description": "Acquisition ",
    "id": "d0be8f38-2487-4db9-9a5d-a65a21b39179",
    "createdAt": "2023-05-18T12:57:39.618012",
    "deletedAt": null,
    "updatedAt": null,
    "updatedBy": null,
    "deletedBy": null,
    "createdBy": "SeederData"
  }
]

export const contactUsPath: string = `/financing/api/${environment.apiVersion}/Notification/SendEmailFromSite`;
export const loanPath: string = `/financing/api/${environment.apiVersion}/Notification/SendEmailFromSite`;