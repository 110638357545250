<div class="content b">
  <section class="banner">
    <div class="texts">
      <h2>{{ 'contents.bannerHead' | translate }}</h2>
      <p>{{ 'contents.bannerPara' | translate }}</p>
      <!-- <button class="loan-btn" (click)="togglePopup()">{{ 'for-merchants.loan.apply' | translate }}</button> -->

      <div class="popup-overlay" *ngIf="isPopupVisible">
        <div class="popup">
          <div class="pointer" (click)="closePopup()">
            <img src="../../assets/icons/error.svg" width="25" height="25" />
          </div>
          <app-apply-for-loan-form (closePopup)="closePopup()"></app-apply-for-loan-form>
        </div>
      </div>
    </div>

    <div class="image">
      <img *ngIf="language == 'en-US'" class="en-img" src="../../../../../assets/images/banner-img.svg" loading="lazy"
        alt="" />
      <img *ngIf="language == 'ar-SA'" class="ar-img" src="../../../../../assets/images/banner-img.svg" loading="lazy"
        alt="" />
    </div>
  </section>

  <app-calculate-finance></app-calculate-finance>
  <app-get-approved></app-get-approved>
  <app-why-hala-financing></app-why-hala-financing>
  <app-faqs></app-faqs>
  <app-risk-warning></app-risk-warning>
</div>

<!-- <div
    style="width: 100%; height: 100vh; display: flex; margin: 0 auto; justify-content: center; align-items: center; gap: 4px; font-size: 35px; //font-family: 'Outfit';">
    <span style="color: #5B0E9F; font-size: 40px;">Hala, </span>
    <h2 style="
    height: auto; position: relative; margin: 0; font-size: 40px; //font-family: 'Outfit'; width: auto;">we
        are coming soon!</h2>
</div> -->