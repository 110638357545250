<div class="button" dir="ltr">
  <button mat-raised-button [disabled]="btnOneDisable" [class.next]="btnOneDisableColor" [title]="titleOne"
    [type]="buttonType" [style.background]="buttonColorOne" [style.height]="buttonHeight"
    [style.color]="buttonTextColorOne" [style.border-radius]="borderRadius" [style.width]="buttonWidth"
    [style.max-width]="buttonWidthSM" (click)="buttonOneClicked()">
    {{ 'contactus.form.customerService' | translate }}
  </button>
  <button mat-raised-button [disabled]="btnTwoDisable" [class.next]="btnTwoDisableColor" [title]="titleTwo"
    [type]="buttonType" [style.background]="buttonColorTwo" [style.height]="buttonHeight"
    [style.color]="buttonTextColorTwo" [style.border-radius]="borderRadius" [style.width]="buttonWidth"
    [style.max-width]="buttonWidthSM" (click)="buttonTwoClicked()">
    {{ 'contactus.form.salesService' | translate }}
  </button>
  <button mat-raised-button [disabled]="btnThreeDisable" [class.next]="btnThreeDisableColor" [title]="titleThree"
    [type]="buttonType" [style.background]="buttonColorThree" [style.height]="buttonHeight"
    [style.color]="buttonTextColorThree" [style.border-radius]="borderRadius" [style.width]="buttonWidth"
    [style.max-width]="buttonWidthSM" (click)="buttonThreeClicked()">
    {{ 'contactus.form.complaint' | translate }}
  </button>
</div>